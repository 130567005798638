@keyframes example {
  0% {
      left: -50vw
  }

  to {
      left: 98vw
  }
}
.MobileMenu{
  position: absolute;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    top: 0;
  /* display: none; */
}
.Ovaler{
  border: 1vw solid #fff;
  height: 121vw;
  width: 121vw;
  border-radius: 100vw;
  position: absolute;
  left: -73vw;
  top: 33vw;
}
@keyframes load {
  0%,to {
      transform: scaleX(.5)
  }

  50% {
      transform: scaleX(1)
  }
}
@keyframes shadow {
  from {
    box-shadow: 0 0 2.5vw 3vw rgba(180,25,188);
  }
  to {
    box-shadow: 0 0 3.5vw 3.5vw rgba(180,25,188);
  }
}
.Link_Block{
  position: relative;
  top: 58vw;
}
@keyframes load-r {
  0%,to {
      transform: scaleX(1.5)
  }

  50% {
      transform: scaleX(calc(1/3))
  }
}
.Link_Block a{
  font-family: 'Economica';
}

*,.star {
  margin: 0;
  padding: 0
}

#root {
  position: relative
}

.star {
  transition: opacity .3s;
  position: absolute;
  background-size: 2vw 5vh;
  background-position: center;
  background-repeat: no-repeat;
  height: 5vh;
  width: 2vw;
  color: #f0f8ff
}
html,body{
  height: 100%;
  position: relative;
}
.Main,.firstPageContainer {
  height: 100%;
  width: 100%;
}

.bar1er,.bar2er,.bar3er {
  width: 2.1vw;
  border-radius: 25vw;
  height: 0.3vw;
  background-color: #fff;
  margin: 0.4vw 0;
  transition: none
}

#firstmenu1 {
  z-index: 12;
  transition: opacity 1s;
  top: 5.5vw;
}

.ContectContainer {
  position: absolute;
  background: 0 0;
  overflow: hidden;
  top: 0;
  width: 100vw;
  height: 100vh
}

#text1 {
  left: -1vw;
  bottom: 13vw;
  transform: rotateZ(298.2deg)
}

#text2 {
  left: 8.4vw;
  bottom: 24vw;
  transform: rotateZ(322.9deg)
}

#text3 {
  bottom: 29.6vw;
  left: 21.5vw;
  transform: rotateZ(-11.4deg)
}

#text4 {
  left: 34.9vw;
  bottom: 29.4vw;
  transform: rotateZ(11deg)
}

#text5 {
  left: 47.3vw;
  bottom: 23.8vw;
  transform: rotateZ(35deg)
}

#text6 {
  left: 55.3vw;
  transform: rotateZ(58.1deg);
  bottom: 15vw
}

.sketch {
  position: fixed;
    /* display: none!important;  */
  top: 0;
  z-index: 10
}

.text {
  overflow: hidden
}





@keyframes blinker {
  50% {
    opacity: 40%;
  }
}

.bigtext div {
  transition: opacity 1s
}
/* .bigtext{
  animation: blinker 2.3s linear infinite !important;
} */
.bigtext,.bigtextoff {
  position: relative;
  z-index: 9999;
  color: #c8bbc3
}

.bigtext,.bigtextoff:hover {
  text-transform: uppercase
}

.bigtextoff {
  opacity: 100!important
}

.bigtextoff:hover,.block3 .missiontext span {
  color: #fff;
  font-family: 'montserrat_armblack'
}

.block3 .missiontext span {
  display: block;
  line-height: 1.5vw;
  font-weight: 700;
  color: #000;
  width: 100%;
  margin-bottom: 2vw;
  position: relative;
  font-size: 1.3vw
}

.text {
  position: relative;

  z-index: 99999
}


.TextContainer {
  position: absolute;
  z-index: 11;
  height: 2vw;
  width: 12vw;
  transform: rotateZ(295deg);
  /* animation: blinker 2.3s linear infinite; */
}



.blur,.dot {
  position: absolute;
  animation: shadow 1s infinite alternate;
  display: none;
  border-radius: 100%
}

.blur {
  z-index: 0;
  top: 4vw;
  left: 38%;
  background-color: transparent;
  height: 2vw;
  width: 2vw
}

.dot {
  left: 41%;
  margin-top: 2.7vh;
  background-color: #fff;
  height: 1.9vw;
  width: 1.9vw;
  z-index: 100
}

.block3 .missiontext p,.text {
  font-family: 'montserrat_armstadart';
  font-size: 1.6vw;
  color: #fff
}

.block3 .missiontext p {
  letter-spacing: 0;
  position: relative;
  width: 49vw;
  text-align: justify;
  display: flex;
  line-height: 1.6vw;
  font-size: 1.1vw;
  color: #5a5a5a
}

.planet {
  position: absolute;
  transition: opacity .3s;
  background-image: url("/media/src/images/Artboard 4a.png")!important;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 67vw;
  left: 17.5vw;
  height: 28.1vw
}

.BackgroundStars {
  position: relative;
  margin: 0;
  overflow: hidden;
  padding: 0;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw
}

.language,.logotype {
  transition: opacity 1s
}

.language {
  cursor: pointer;
  top: 5.6vw;
  position: absolute;
  z-index: 11;
  text-decoration: none;
  left: 90vw;
  font-size: 1.5vw;
  font-family: 'montserrat_armstadart';
  color: #fff
}

.logotype {
  background: no-repeat;
  position: relative;
  top: 5vw;
  background-image: url(/media/src/images/logo-white-4.png);
  height: 5vw;
  background-position: center;
  background-size: contain;
  left: 43vw;
  width: 15vw
}

.ContextBlock {
  position: absolute;
  background-repeat: no-repeat;
  transition: 0s;
  width: 100vw;
  text-align: center;
  height: 100vh;
  background-image: url("/media/src/images/Artboard 18.png");
  background-position: cover;
  background-size: 100vw 110vh
}

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden
}

#img_4_colored {
  margin-top: 0.1vw;
  margin-left: -3.5vw;
  height: 7vw;
  width: 7.3vw
}

#img_5_colored {
  margin-top: 1vw;
  height: 5.7vw;
  margin-left: -4.9vw;
  width: 9.7vw
}

#img_2_colored,#img_3_colored {
  height: 6.7vw;
  margin-top: 0
}

#img_3_colored {
  width: 6.3vw;
  margin-left: -2.6vw
}

#img_2_colored {
  width: 6.8vw;
  margin-left: -3.4vw
}

#img_1_colored {
  height: 6.6vw;
  width: 7.7vw;
  margin-top: 0;
  margin-left: -3.7vw
}

.img_title {
  text-transform: uppercase;
  font-family: 'montserrat_extrabold';
  font-size: 1.6vw;
  margin-top: -.1vw
}



.Slick-item-rec-container .slick-prev {
  display: none!important
}

.RecContainerPart .Slick-item-rec-container .slick-dots {
  position: relative;
  left: -34.8vw;
  top: -.5vw
}

.RecContainerPart .Slick-item-rec-container .slick-slider {
  top: 0
}

.RecomendatorPhotoText {
  height: 27vw;
  width: 49vw
}

.RecomendatorPhotoRec img {
  height: 35vw;
  margin-top: -6vw
}

.recdivText p {
  text-align: justify;
  margin-top: 1vw;
  WIDTH: 90%;
  margin-left: 2vw;
  font-size: 1.4vw
}

.nameFio {
  POSITION: RELATIVE;
  font-size: 1.4vw;
  font-weight: 400;
  FLOAT: RIGHT;
  LEFT: 16.6VW;
  TOP: 2.1VW
}

.NAMEtEXT {
  FLOAT: right;
  font-family: 'montserrat_extrabold';
  text-transform: uppercase;
  FONT-SIZE: 1.7VW;
  POSITION: RELATIVE;
  margin-right: 2.6vw
}

.recdivText {
  background-color: rgba(236,236,236,.5);
  width: 47vw;
  margin-top: 5vw;
  text-align: left;
  height: 16vw;
  margin-left: 1vw;
  border-radius: 2vw
}

.RecContainerPart {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: -5vw;
  height: 40vw;
  padding-left: 8vw;
  overflow: hidden;
  background-image: url("/media/src/images/Artboard 8 copy.png")!important
}

.Slick-item-rec-Block div {
  float: left
}

.Slick-item-rec-container {
  position: relative;
  top: -5vw
}

.Slick-item-container .slick-dots {
  bottom: 0
}

.Slick-item-container .slick-slider {
  z-index: 0;
  top: 0
}

.Slick-item-container .slick-slide img {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.sliderphoto {
  width: 15vw
}

#euro,#nur {
  height: 5.2vw
}

#nur {
  margin-top: 0.5vw;
  margin-left: 3.7vw
}

#euro {
  margin-top: 0.4vw;
  margin-left: 3.4vw
}

#tech {
  height: 4.6vw;
  margin-left: 2.2vw;
  width: 8.7vw;
  margin-top: 1vw
}

#epic {
  margin-left: 3.8vw;
  margin-top: 0.5vw;
  height: 4.7vw
}

#Coaf {
  height: 6vw
}

.PartnersContainer ul {
  list-style-type: none
}

.PartnersContainer ul li {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-left: 1.4vw;
  width: 14vw;
  margin-top: 4vw
}

#gazar {
  margin-left: 1.4vw;
  margin-top: 0.5vw;
  height: 4.9vw
}

#world {
  margin-left: 2.5vw;
  height: 4.1vw;
  width: 8.3vw;
  margin-top: 0.6vw
}

#tumo {
  height: 3.6vw;
  margin-left: 2vw;
  margin-top: 1vw
}

#nation {
  height: 5.8vw;
  margin-left: 4.5vw;
  margin-top: -.5vw
}

#eurasia {
  margin-left: 1vw;
  height: 4vw;
  margin-top: 1.1vw
}

#aua {
  height: 2.6vw;
  margin-left: -.2vw;
  margin-top: 1.8vw
}

#uwc {
  height: 2.5vw;
  margin-left: 0.4vw;
  margin-top: 1.9vw
}

#coaf {
  margin-left: 2vw;
  height: 4vw;
  margin-top: 1.1vw
}

#bel {
  height: 3.9vw;
  margin-left: 0.7vw;
  margin-top: 0.9vw
}

#block3context,#recomendationBlockPartner {
  background-image: url("/media/src/images/Artboard 8 copy.png");
  background-position: center;
  background-size: cover;
  height: 46vw;
  background-repeat: no-repeat
}

.StatusContainer {
  top: 6vw;
  padding-left: 9.6vw;
  width: 100%;
  padding-top: 1vw;
  background-color: rgba(236,236,236,.7);
  height: 55.6%;
  position: absolute
}

#block3context {
  background-image: url("/media/src/images/Artboard 72.png");
  background-size: 100%;
  height: 31vw
}

#tavush1imgct_2_partner {
  left: 41vw;
  top: 2.2vw
}

#kotayqh1imgct_1_partner {
  left: 48.7vw;
  top: -7vw
}

#tavush1imgct_1_partner {
  left: 42.8vw;
  top: 5.6vw
}

#aragatsotn1imgct_1_partner {
  left: 52.6vw;
  top: -3.4vw
}

#gegharh1imgct_1_partner {
  left: 44vw;
  top: -6.2vw
}

#aragatsotn1imgct_2_partner {
  left: 52.6vw;
  top: -7vw
}

.logo1_2019 {
  width: 6.8vw!important;
  margin-top: 0.6vw
}

#trinty {
  width: 4.1vw;
  margin-top: 0.1vw
}

.logo2_2019 {
  width: 4.3vw!important;
  margin-top: 0.2vw
}

.logo3_2019 {
  width: 5.6vw!important;
  margin-top: 0.5vw!important
}

.logo1 {
  width: 5.2vw
}

.logo2 {
  width: 4vw
}

.logo3 {
  width: 5.6vw;
  margin-top: 0.5vw
}

.logo4 {
  width: 3.7vw;
  margin-top: -.3vw
}

.MiniImages2018,.MiniImages2019 {
  overflow: hidden;
  width: 24.9%;
  background: #fff;
  display: inline-block;
  height: 100%
}

.MiniImages2019 {
  width: 30.9%
}

.PartnerContentBlock h1 {
  font-family: 'montserrat_extrabold';
  font-size: 1.6vw;
  position: relative;
  top: 14%
}

#aragatsotn img#aragatsotnPartner {
  position: relative;
  margin-top: -14%;
  right: -9.9%;
  width: 93%;
  height: 11.7vw
}

.PartnerImage,.PartnerImage2018 {
  position: absolute;
  bottom: 9.5%;
  height: 4vw;
  background: #fff;
  width: 90%;
  left: 1.4vw
}

.PartnerImage2018 {
  height: 3.3vw
}

#cfo,#cfo1 {
  height: 2.7vw;
  width: 6.7vw;
  MARGIN-TOP: 0.7VW
}

.PartnerImage img {
  width: 9vw;
  margin-top: 0.4vw
}

.PartnerContentBlock>p {
  position: relative;
  text-align: justify;
  margin-left: 8%;
  margin-top: 5vw;
  width: 85%;
  font-size: 1vw
}

.LocationImageContainerPartner {
  display: none;
  overflow: hidden;
  transition: all .1s;
  z-index: 0;
  position: absolute;
  height: 22.6vw;
  left: 10vw;
  width: 25vw
}

.LogoBlock {
  z-index: 11
}

.PartxerOneText {
  position: absolute;
  left: 7.8vw;
  font-size: 3.4vw;
  top: 46.8vh
}

.PartxerOneText h1 {
  font-family: 'montserrat_armblack'
}

#ContextBlockPartner {
  transition: 0s;
  width: 100vw;
  position: absolute;
  background-color: white;
  text-align: center;
  height: 100vh;
  background-image: url("/media/src/images/Artboard 47.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center
}

.FormContainerPartner input::placeholder,.FormContainerPartner textarea::placeholder {
  color: #fff;
  opacity: 1
}

.FormContainer form {
  position: relative;
  height: 30vw;
  margin-right: auto;
  margin-left: auto;
  z-index: 11;
  top: 8.3vw
}

.contactsUl i {
  color: transparent;
  background-image: url("/media/src/images/Artboard 39.png");
  background-size: 3vw;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 4vw
}

*{
  outline: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.faq_ul li>p {
  overflow: hidden;
  width: 48vw;
  position: relative;
  left: 2vw;
  transition: height .15s;
  height: 0;
  font-size: 1.3vw;
  color: #000
}

.faq_ul li h4::before {
  content: '•';
  font-size: 2vw;
  position: relative;
  top: -.5vw;
  margin-right: 1vw;
  float: left;
  height: 1vw;
  width: 1vw
}

.block1 h1 {
  top: 7vw
}

#ContextBlockVolunteer {
  background-image: url("/media/src/images/Artboard 1 (2).png")
}

.PartxerOneTextVol {
  position: absolute;
  left: 8.8vw;
  font-size: 2.5vw;
  top: 52.8vh
}

.PartxerOneTextVol h1 {
  font-family: 'montserrat_armblack'
}

.TimesBlock p {
  color: #fff;
  font-size: 1.3vw;
  margin-top: 0.5vw;
  margin-left: 4.8vw
}

#color_time_3 H1 {
  margin-left: 2.5vw
}

#block4toscroll .skyBlock {
  top: 1vw
}

#FunText {
  margin-top: 5vw;
  font-size: 1.1vw
}

.recomedationtext b {
  color: #000
}

.FormContainer input,.FormContainer textarea {
  font-size: 1vw;
  color: #5a5a5a;
  border-radius: 1vw;
  border: 0.3vw solid #fff;
  background-color: #eaeaea
}

.FormContainer input {
  height: 0.8vw;
  padding: 1.5vw;
  width: 20vw;
  margin-top: 0.5vw;
  position: relative;
  display: block
}

.FormContainer textarea {
  padding: 1vw;
  position: absolute;
  left: 24vw;
  margin-top: 0;
  height: 12.5vw;
  width: 40vw;
  resize: none
}

#timesh1 {
  letter-spacing: 0.1vw
}

.TeamBlock {
  height: 28vw;
  position: relative
}

.TeamBlock img,.menuPoints li a {
  cursor: pointer
}

.slideraname {
  font-family: 'montserrat_armstadart';
  font-weight: 700;
  font-size: 1.1vw
}

.slideraCeo {
  font-size: 0.9vw;
  font-family: 'montserrat_extralight'
}

.MenDsicriptionBlock {
  position: absolute;
  opacity: 0;
  width: 25vw;
  text-align: left;
  z-index: 15!important;
  top: 25.1vw;
  margin-left: 4vw
}

#aboutmenu {
  left: 38.2vw
}

.TitlePlanet h1 {
  width: 34vw
}

.block1 h1 {
  top: 50%
}

.menuPoints li a:hover {
  color: #000!important
}

.sliderphoto {
  display: block
}

.block5context {
  top: 5vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 38vw;
  width: 90vw
}

#slidePhoto1 {
  position: relative;
  top: -5.2vw;
  width: 18.5vw
}

#slidePhotoMarine {
  width: 17vw;
  position: absolute;
  top: -1.8vw
}

#slidePhotoEva {
  width: 15.9vw;
  top: -.2vw
}

#slidePhoto2,#slidePhotoEva,#slidePhotoHakob {
  position: relative
}

#slidePhotoHakob {
  width: 16vw;
  top: -1.3vw
}

#slidePhoto2 {
  top: 3vw;
  width: 14.2vw;
  margin-left: 3.7vw
}

#slidePhotoGavar,#slidePhotoMika {
  width: 17vw;
  position: relative;
  top: -1.8vw
}

#slidePhotoMika {
  width: 16.5vw;
  top: -1.2vw
}

#slidePhotoAnjelika,#slidePhotoNora {
  width: 15.2vw;
  position: relative;
  top: -.4vw
}

#slidePhotoNora {
  width: 16.1vw;
  top: -.5vw
}

#slidePhotoVanik {
  width: 17vw;
  top: -3vw
}

#slidePhoto3,#slidePhotoVanik,.Texteer span {
  position: relative
}

#slidePhotoVardan {
  width: 17vw;
  position: relative;
  top: -1.8vw
}

#slidePhoto3 {
  top: -1vw;
  width: 16.7vw
}

#slidePhotoMaria,#slidePhotoNarek {
  width: 17vw;
  position: relative;
  top: -1.9vw
}

#slidePhotoNarek {
  width: 18vw;
  top: -3.2vw
}

#slidePhotoGayane {
  width: 17vw;
  position: relative;
  top: -1.8vw
}

.Texteer span {
  display: block;
  text-align: center;
  width: 78.1vw;
  font-weight: 400;
  margin-left: 12.2vw
}

#pmst h2,.tt2 {
  margin-top: 2vw
}

.Texteer {
  POSITION: ABSOLUTE;
  TOP: 25.5VW;
  WIDTH: 100%;
  HEIGHT: 17VW;
  font-size: 1.2vw;
  TEXT-ALIGN: LEFT;
  font-family: 'montserrat_armstadart';
  font-weight: 700
}

#aboutback {
  background-image: url("/media/src/images/Artboard 1 copy 2 (3).png");
  background-size: cover
}

.Loger1 {
  background-image: url("/media/src/images/Artboard 7 (2).png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  height: 13vw;
  top: 18.5vw;
  width: 16.9vw;
  margin-left: 41.6VW
}

#slidePhotoedmon {
  width: 15.1vw;
  position: relative;
  height: 23vw;
  top: -.5vw
}
#slidePhotoTigran{
  position: relative;
  height: 25vw;
  width: 20vw;
  top: -2.5vw;
  object-fit: cover;
}

#slidePhoto4 {
  position: relative;
  top: -1.6vw;
  width: 16vw
}

#slidePhoto5,#slidePhoto56 {
  position: relative;
  width: 16.5vw
}

#slidePhoto5 {
  top: -.5vw
}

#slidePhoto56 {
  top: 0
}

.mistext {
  width: 43vw
}

.faq_ul li a {
  margin: 0;
  padding: 0
}

.TitlePlanet {
  transition: all .4s ease!important
}

.skyBlock h1 {
  font-family: 'montserrat_extrabold';
  font-weight: 700
}

.block1 {
  overflow: hidden;
  display: block;
  position: relative;
  height: 47.7vw;
  width: 100vw
}

.block1context,.skyBlock h1 {
  position: relative;
  margin-left: auto;
  margin-right: auto
}

.block1context {
  top: 4vw;
  height: 38vw;
  width: 90vw
}

.RecomendatorPhoto img,.skyBlock h1 {
  display: block
}

.skyBlock h1 {
  z-index: 11;
  font-size: 4vw;
  text-transform: uppercase;
  top: 40%
}

#ContextBlockParent {
  background-color: #fff!important;
  background-image: url(/media/src/images/1.png)!important
}

#img_1_voler {
  height: 5.7vw;
  margin-top: 0.8vw;
  margin-left: -3vw;
  width: 6vw
}

#img_2_voler {
  height: 6.9vw;
  width: 5vw;
  margin-top: 0.7vw;
  margin-left: -2.8vw
}

#img_3_voler {
  height: 5.5vw;
  width: 5.5vw;
  margin-top: 1.9vw;
  margin-left: -3.1vw
}

#img_4_voler {
  height: 6.9vw;
  width: 6.4vw;
  margin-top: 1vw;
  margin-left: -2.5vw
}

#img_5_voler {
  height: 5.4vw;
  width: 3.6vw;
  margin-top: 1.8vw;
  margin-left: -2.1vw
}

#volunteerstatcontent {
  background-size: 110%;
  height: 32.8vw;
  background-position-y: -13vw
}

.PartxerOneTextParent {
  position: absolute;
  left: 15.8vw;
  font-size: 2.5vw;
  top: 52.8vh
}

.PartxerOneTextParent h1,.block3 .missiontext h2 {
  font-family: 'montserrat_armblack'
}

#statuscontvol {
  height: 51.9%
}

#recomendationBlock {
  background-size: 19vw;
  background-image: url(/media/src/images/planet3.png);
  background-position: left 1vw;
  height: 40vw;
  background-repeat: no-repeat
}

#fparagrap_1,#fparagrap_3 {
  color: #fff;
  font-size: 1.5vw;
  margin-top: 0.1vw;
  margin-left: 5.4vw
}

#fparagrap_3 {
  margin-top: 0.3vw;
  margin-left: 4.8vw
}

#fparagrap_2 {
  color: #fff;
  font-size: 1.4vw;
  margin-top: 0.2vw;
  margin-left: 5.8vw
}

#fl_1,#fl_2,#fl_3,.TimesBlock H1 {
  color: #fff;
  font-family: 'montserrat_armblack'
}

#fl_2,#fl_3,.TimesBlock H1 {
  font-size: 3.7vw
}

#fl_3,.TimesBlock H1 {
  margin-left: 2.2VW;
  line-height: 3.2vw;
  margin-top: 4.3vw
}

.TimesBlock H1 {
  margin-left: 3.8VW;
  line-height: 2.7vw;
  margin-top: 3.2vw;
  font-size: 3vw
}

#fl_1,#fl_2 {
  line-height: 3.3vw;
  margin-top: 4.2vw
}

#fl_2 {
  margin-left: 3.4VW
}

#fl_1 {
  margin-left: 4.3VW;
  font-size: 3.5vw
}

.TimesBlock {
  text-align: left;
  height: 14vw;
  margin-left: 0.8vw;
  float: left;
  position: relative;
  z-index: 11;
  width: 17vw;
  border-radius: 3.6vw
}

#color_time_1 {
  background: #fc5858
}

#color_time_2 {
  background: #c14d8e
}

#color_time_3 {
  background: #764e9c
}

.TimeBlockCont {
  height: 13vw;
  width: 55vw;
  margin-left: 13.4vw;
  margin-top: 2vw;
  margin-right: auto
}

.block3 #FunTextparent {
  font-size: 1.1vw;
  margin-left: 6vw;
  width: 43vw;
  top: -.4vw
}

.block3 .missiontext h2 {
  display: block;
  line-height: 1.5vw;
  font-weight: 700;
  color: #000;
  width: 100%;
  margin-bottom: 2vw;
  position: relative;
  font-size: 1.3vw
}

#recomendationContextBlockVol .line {
  transform: rotateZ(-3deg);
  width: 48vw;
  bottom: 3vw;
  background-position: right;
  background-image: url(/media/src/images/mini_line.png);
  background-size: cover;
  position: absolute;
  top: 25.8vw;
  z-index: 11;
  left: 37vw
}

#recomendationContextBlock {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 38vw;
  width: 81vw
}

#recomendationContextBlockVol {
  width: 81vw!important
}

#recomendationContextBlock {
  top: 6vw
}

.recomedationSliderItemContainer {
  position: relative;
  padding-top: 8vw
}

.contactsUl span,.recomedationSliderItemContainer div {
  display: block;
  float: left
}

.recomedationtext {
  margin-left: 8vw;
  display: flex;
  position: relative;
  top: -5vw;
  font-size: 1.1vw;
  height: 15vw;
  z-index: 11;
  line-height: 1.4vw;
  color: #878787;
  text-align: justify;
  width: 50%
}

.RecomendatorPhoto {
  position: relative;
  left: 5vw
}

#MikaRec {
  position: absolute;
  width: 17vw;
  top: -10.6vw
}

#recskyes {
  top: 5vw
}

.FormContainer {
  position: relative;
  background-image: url(/media/src/images/join.png);
  margin-top: 3vw;
  height: 30vw;
  margin-left: 10vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  display: block
}

#sendbutton {
  cursor: pointer;
  background-color: #fdc248;
  color: #fff;
  padding-top: 0.6vw;
  height: 3vw;
  width: 11vw;
  font-size: 1.3vw;
  font-family: 'montserrat_extrabold'
}

#joincontainer {
  background-image: url(/media/src/images/planet3.png);
  background-repeat: no-repeat;
  height: 38vw;
  background-size: 17vw
}

#pmst h2 {
  top: 1VW;
  text-transform: uppercase;
  POSITION: RELATIVE
}

.contactsUl span {
  z-index: 11;
  cursor: pointer;
  color: #000;
  font-family: 'montserrat_extralight';
  font-weight: 700;
  position: relative;
  left: 0
}

.ImgeContainer h1 {
  font-family: 'montserrat_extrabold';
  text-transform: uppercase;
  color: #ffff;
  font-size: 1.3vw;
  margin-top: 1.8vw
}

.BelinneLogoSlider {
  height: 7vw
}

#davidr,#mariar,#yevar {
  position: absolute
}

#mariar,#yevar {
  top: -11.9vw;
  width: 17.9vw
}

#yevar {
  top: -9.8vw;
  width: 16.5vw
}

#davidr {
  top: -9.7vw;
  width: 16.4vw
}

.ImgeContainer img {
  height: 5vw
}

.ImgeContainer {
  height: 13vw;
  margin-top: 2.7vw;
  width: 100%
}

.RolesContainer {
  height: 31vw;
  width: 50vw;
  margin-top: 3vw;
  margin-left: 18.3vw;
  overflow: hidden
}

.RolesBlock:hover {
  background: #fdc248
}

#block3contextVol {
  height: 40vw
}

#timesh1 {
  font-size: 5.1vw;
  top: 1.3vw;
  margin-left: 2.3vw
}

#fier {
  margin-left: 1.3vw
}

#block3toscrollVolTime {
  background: 0 0;
  background-size: 110%;
  background-position-y: -10vw;
  height: 27vw
}

.RolesBlock,.block1contextGuest video,.recomert {
  position: relative;
  z-index: 11
}

.RolesBlock {
  cursor: pointer;
  float: left;
  border-radius: 1vw;
  height: 14vw;
  margin-top: 1vw;
  margin-left: 1vw;
  width: 14vw;
  background: #c1c1c1
}

.recomert {
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  font-family: montserrat_extrabold;
  font-weight: 700;
  display: block;
  font-size: 4vw
}

.pNameS {
  font-family: 'montserrat_armstadart';
  font-weight: 700;
  font-size: 1.4vw;
  letter-spacing: 0.1vw
}

.psng {
  background-image: url("/media/src/images/Artboard 6 (3).png");
  position: absolute;
  height: 4vw;
  background-size: 100%;
  width: 46.2vw;
  z-index: 11;
  background-repeat: no-repeat;
  top: 26.4vw;
  LEFT: -4.7VW
}

.Pceo {
  position: relative;
  font-size: 0.9vw
}

#block4toscrollparent {
  background-image: url(/media/src/images/haha.png)
}

.pdis {
  position: absolute;
  left: 22vw;
  top: 23.5vw;
  text-align: left
}

#pmst {
  width: 49.1vw;
  text-align: right;
  left: 32.8vw;
  top: 2.2vw
}

#partnerimg {
  background-image: url(/media/src/images/ars.png);
  background-size: 21.7vw;
  background-position-y: -2vw;
  height: 31.5vw;
  top: -2.6vw;
  left: -.7vw
}

.TitlePlanet::after {
  content: "";
  width: 70vw;
  height: 70vw;
  background-size: cover;
  background-position: 50%;
  position: absolute;
  top: -20vw;
  left: -20vw;
  z-index: 0;
  background: -webkit-radial-gradient(center,ellipse cover,rgba(180,25,188,.5) 0,rgba(180,25,188,.5) 7%,rgba(26,7,113,0) 55%,rgba(3,0,63,0) 100%);
  background: -webkit-radial-gradient(center,ellipse,rgba(180,25,188,.5) 0,rgba(180,25,188,.5) 7%,rgba(26,7,113,0) 55%,rgba(3,0,63,0) 100%);
  background: radial-gradient(ellipse at center,rgba(180,25,188,.5) 0,rgba(180,25,188,.5) 7%,rgba(26,7,113,0) 30%,rgba(3,0,63,0) 100%)
}

.StartAnim,.TitlePlanet {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 0;
  position: absolute;
  top: 17vh;
  left: 35vw;
  color: #fff;
  background-image: url("/media/src/images/Artboard 4.png");
  background-size: contain;
  background-position: -1vw;
  background-repeat: no-repeat;
  height: 30vw;
  width: 10vw
}

.StartAnim {
  transition: all .4s;
  opacity: 1;
  width: 30vw
}

.TitlePlanet h1,.block1 h1 {
  font-family: 'montserrat_extrabold';
  font-weight: 700
}

.TitlePlanet h1,.menuPoints li {
  text-transform: uppercase;
  font-size: 1vw
}

.TitlePlanet h1 {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  transition: all .4s;
  z-index: 1000;
  position: absolute;
  top: 40%
}

.language2,.logotype2 {
  cursor: pointer;
  z-index: 11;
  transition: all 1s;
  top: 5vw
}

.language2 {
  position: absolute;
  left: 85vw;
  font-size: 1.5vw;
  font-family: 'montserrat_armstadart';
  color: #fff
}

.logotype2 {
  background: no-repeat;
  position: relative;
  background-image: url(/media/src/images/logo-white-4.png);
  height: 3vw;
  background-position: center;
  background-size: contain;
  left: 5vw;
  width: 15vw
}

.menuNav {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  transition: transform 1s;
  border-bottom: 1px solid #f4f6f9;
  text-align: center;
  position: fixed;
  padding-left: 7vw;
  transform: translateY(-15vw);
  transform: translateY(-2.9vw);
    display: flex;
    align-items: center;
    justify-content: end;
  z-index: 15;
  height: 10vw;
  background: #fff;
  width: 100vw
}

.elem1logo {
  position: relative;
  height: 5vw;
  margin-top: 3.2vw;
  cursor: pointer;
  width: 12vw;
  background-image: url(/media/src/images/logo-black.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center
}

.menuPoints {
  display: flex;
  justify-content: center;
  margin: 0 2vw;
  align-items: center;
  width: 62vw;
}

.menuPoints li {
  letter-spacing: 0.1vw;
  color: #aaa;
  font-family: 'montserrat_armstadart';
  font-weight: 700;
  margin-top: 3.3vw;
  display: inline-block;
  margin-right: 0;
  padding: 1vw
}

.BurgerMenucontainer {
  cursor: pointer;
  position: absolute;
  top: 5.8vw;
  left: 85vw;
  width: 2%;
    /* height: 16%; */
}
.bar1,.bar2,.bar3 {
  width: 2.1vw;
  border-radius: 25vw;
  height: 0.3vw;
  background-color: #5e5e5e;
  margin-bottom: 16.8%;
  transition: none;
}



.block1contextGuest video {
  border: 0;
  margin-top: 3vw;
  width: 60vw
}

.block1VideoGuest {
  overflow: hidden;
  display: block;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("/media/src/images/Artboard 4 (3).png");
  height: 47.7vw;
  width: 100vw
}

.block1contextGuest {
  top: 4vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 38vw;
  width: 90vw
}

.block1context div,.block2context div,.block3context div,.block5context div {
  display: block
}

.line,.textblock {
  position: relative;
  height: 100%;
  width: 50%
}

.block1 h1 {
  z-index: 11;
  left: -26vw;
  font-size: 4vw
}

.line {
  height: 15%;
  width: 100%;
  z-index: 0;
  background-image: url(/media/src/images/line1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat
}

.missiontext {
  position: relative;
  top: 10vw;
  z-index: 11;
  font-size: 1.5vw;
  text-align: center;
  width: 40vw;
  color: #9c9c9c;
  margin-left: auto;
  margin-right: auto
}

.miniplanet,.missionimage {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat
}

.miniplanet {
  left: 24vw;
  height: 50vw;
  width: 25vw;
  top: 2vw;
  background-image: url(/media/src/images/planet.png);
  background-position: top
}

.missionimage {
  left: 100%;
  background-image: url("/media/src/images/Artboard 38.png");
  background-position: 0 4vw;
  top: 0;
  height: 100%;
  width: 100%
}

.socialnav,.socialnav li {
  z-index: 100;
  margin: 0;
  padding: 0
}

.socialnav {
  list-style-type: none;
  position: fixed;
  bottom: 0;
  left: 79vw
}

.socialnav li {
  display: inline-block;
  /* padding: 1vw; */
}

.block1 h1,.skyBlock h2,.sociales a {
  position: relative;
  display: block
}

.block2 .line,.sociales a {
  background-size: cover;
  padding: 0
}

.block2,.sociales a {
  background-repeat: no-repeat
}

.sociales a {
  height: 5vw;
  width: 4vw;
  visibility: 1;
  color: transparent;
  background-image: url(/media/src/images/instagram.png);
  background-position: center right
}

#linkedin {
  background-image: url(/media/src/images/in.png)
}

#youtube {
  background-image: url(/media/src/images/youtube.png)
}

#facebook {
  background-image: url(/media/src/images/fb.png)
}

.block2 {
  overflow: hidden;
  display: block;
  position: relative;
  background-image: url("/media/src/images/Artboard 4.png");
  background-size: 23vw;
  background-position: 86vw 0;
  height: 51vw;
  width: 100vw
}

.block2context {
  top: 9vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 38vw;
  width: 70vw
}

.block2 .line {
  position: absolute;
  z-index: 12;
  background-image: url("/media/src/images/Artboard 29.png");
  height: 5.2vw;
  margin: 0;
  width: 100vw;
  bottom: 5.7vw;
  left: -15vw
}

.block2 .skyBlock {
  top: 5vw
}

.skyBlock {
  text-align: center;
  position: absolute;
  z-index: 0;
  height: 10vw;
  width: 100vw
}

.bigRegText {
  font-family: 'montserrat_extrabold';
  font-size: 4vw;
  left: -4vw;
  top: 3vw;
  position: relative
}

.gicb {
  position: relative;
  top: 30.1vw;
  left: 13vw;
  background-size: 65vw;
  background-image: url(/media/src/images/partners/gic.png);
  background-repeat: no-repeat;
  width: 100%;
  z-index: 12;
  height: 20%
}

.RecomendatorPhotoRec {
  height: 27vw;
  width: 28vw;
  background: #fff
}

#funimage {
  background-image: url("/media/src/images/Artboard 37.png")
}

.skyBlock h2 {
  text-transform: uppercase;
  z-index: 11;
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  font-family: 'montserrat_extrabold';
  font-weight: 700;
  font-size: 4vw
}

#block3contextGuest,#containcontextGuest {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center
}

#block3contextGuest {
  background-image: url("/media/src/images/Artboard 72.png");
  height: 31vw
}

#containcontextGuest {
  background-color: #fff;
  transition: 0s;
  width: 100vw;
  position: absolute;
  text-align: center;
  height: 100vh;
  background-image: url("/media/src/images/Artboard 1 (3).png");
}

.close,.skyes {
  position: absolute
}

.skyes {
  transition: all 1s;
  animation: example 80s linear infinite;
  height: 10vw;
  left: 98vw;
  width: 65vw
}

.close {
  left: 27vw;
  top: 2.5vw;
  width: 2.5vw;
  height: 3vw;
  opacity: 0.3
}

.close:hover {
  opacity: 1
}

.close:after,.close:before {
  position: absolute;
  content: ' ';
  height: 49px;
  width: 12px;
  background-color: #000
}

.close:before {
  transform: rotate(45deg)
}

.close:after {
  transform: rotate(-45deg)
}

.block3,.sky {
  background-repeat: no-repeat
}

.sky {
  position: absolute;
  height: 5vw;
  width: 13vw;
  background-image: url(/media/src/images/cloud.png);
  background-size: contain;
  background-position: center
}

#sky1 {
  top: 3vw
}

#agbu {
  height: 7vw;
  margin-left: 0.7vw;
  margin-top: -.7vw
}

#sky2 {
  left: 20vw
}

#sky3 {
  left: 15vw;
  top: 5vw;
  background-size: 20vw;
  width: 20vw
}

#sky4 {
  left: 50vw;
  top: 2vw
}

.block3 {
  overflow: hidden;
  padding-left: 0;
  display: block;
  position: relative;
  background-position: 0 -10vw;
  background-size: cover;
  background-image: url("/media/src/images/Artboard 30.png");
  height: 44vw;
  width: 100vw
}

#teach {
  height: 4.5vw
}

.PartnersLogoContainer {
  position: relative;
  top: 6vw;
  float: left;
  margin-top: 2vw;
  height: 7vw;
  width: auto
}

.PartnersContainer {
  margin-top: 15vw
}

.partnersLogoBlock img {
  height: 6vw
}

.partnersLogoBlock {
  position: relative;
  left: -2vw;
  float: left;
  margin-left: 2vw
}

.PartnersContainer h1 {
  font-family: 'montserrat_armblack';
  FONT-SIZE: 4.1VW
}

#contactsblck,#team_slider_container {
  background-image: url("/media/src/images/Artboard 4.png");
  background-repeat: no-repeat;
  background-position: 87vw 2vw;
  background-size: 23vw
}

#recomendationBlock .skyBlock {
  top: 0
}

#contactsblck .skyBlock {
  top: 5vw
}

#contactsblck {
  background-size: 16vw;
  height: 27vw;
  background-position: right 3.5vw;
  background-image: url(/media/src/images/planet4.png)
}

.link_gcik {
  position: relative;
  top: -0.1vw;
  pointer-events: none;
  left: 1vw;
  font-size: 1.4vw
}

.img_discription {
  font-family: 'montserrat_armblack';
  font-size: 3vw
}

#statusimg5 {
  width: 11vw;
  margin-left: -5vw
}

#trinity {
  height: 6vw
}

.Img_Container {
  position: relative;
  top: 2vw;
  height: 10vw
}

.StatusImg {
  position: absolute;
  width: 6.8vw;
  margin-left: -3vw;
  margin-top: -1vw
}

#color_1 {
  background: #774f9d
}

#color_2 {
  background: #c14d8e
}

#color_3 {
  background: #fc5858
}

#color_4 {
  background: #fdc248
}

#color_5 {
  background: #41aab0
}

.TitleContext h1 {
  color: #fff
}

.TitleBlock {
  float: left;
  width: 16vw;
  padding-bottom: 1vw;
  position: relative
}

#MapBlockPartner {
  left: 6vw
}

#block3toscrollPartner {
  background-image: url("/media/src/images/Artboard 8 copy.png");
  background-position: center;
  background-size: cover;
  height: 48vw;
  background-repeat: no-repeat
}

#block3toscroll .skyBlock {
  top: 3vw
}

#block3toscroll .block3context {
  top: 10vw
}

.block3context {
  top: 8vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 4vw;
  height: 30vw;
  width: 90vw
}

.block3 .missionimage {
  width: 68%;
  background-image: url("/media/src/images/Artboard 28.png");
  background-size: 30vw;
  background-position: 0 6vw
}

.block3 .textblock {
  width: 60%
}

.block3 #FunText {
  font-size: 1.1vw
}

.block3 .missionimage {
  top: 2vw
}

.block3 .missiontext {
  top: 4vw;
  text-align: left;
  margin: 0;
  left: 3.2vw;
  padding: 0;
  width: 95%;
  position: absolute
}

.block3 .line {
  transform: rotateZ(-3deg);
  width: 48vw;
  left: 36vw;
  bottom: 3vw;
  background-position: right;
  background-image: url(/media/src/images/mini_line.png);
  background-size: cover
}

.PartnerContentBlock {
  background-color: #000;
  background: rgba(10,10,10,.1);
  height: 100%;
  border-radius: 14%
}

.menu-item,.menu-open-button {
  background: #eee;
  border-radius: 100%;
  width: 6vw;
  height: 5.9vw;
  margin-left: -3vw;
  position: absolute;
  color: #fff;
  text-align: center;
  font-size: 1.9vw;
  text-decoration: none;
  line-height: 5.8vw;
  transform: translate3d(0,0,0);
  transition: transform ease-out 200ms
}

.menu-open {
  display: none
}

.lines {
  width: 2.1vw;
  height: 0.2vw;
  background: #596778;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1vw;
  margin-top: 0;
  -webkit-transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms,-webkit-transform 200ms
}

.line-1 {
  -webkit-transform: translate3d(0,-8px,0);
  transform: translate3d(0,-8px,0)
}

.line-2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.line-3 {
  -webkit-transform: translate3d(0,8px,0);
  transform: translate3d(0,8px,0)
}

.menu-open:checked+.menu-open-button .line-1 {
  -webkit-transform: translate3d(0,0,0) rotate(45deg);
  transform: translate3d(0,0,0) rotate(45deg)
}

.menu-open:checked+.menu-open-button .line-2 {
  -webkit-transform: translate3d(0,0,0) scale(.1,1);
  transform: translate3d(0,0,0) scale(.1,1)
}

.menu-open:checked+.menu-open-button .line-3 {
  -webkit-transform: translate3d(0,0,0) rotate(-45deg);
  transform: translate3d(0,0,0) rotate(-45deg)
}

.context,.menu {
  position: absolute
}

.menu {
  opacity: 0;
  margin: auto;
  top: 11vw;
  transition: opacity .3s;
  bottom: 0;
  left: 4vw;
  right: 0;
  width: 6vw;
  height: 6vw;
  text-align: center;
  box-sizing: border-box
}

.context {
  left: 13vw;
  overflow: hidden
}

.menu-item:hover {
  background: #eee;
  color: #3290b1
}

.menu-item:nth-child(3),.menu-item:nth-child(4),.menu-item:nth-child(5),.menu-item:nth-child(6),.menu-item:nth-child(7),.menu-item:nth-child(8),.menu-item:nth-child(9) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms
}

.menu-open-button {
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(.175,.885,.32,1.275);
  transition-timing-function: cubic-bezier(.175,.885,.32,1.275);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(1.1,1.1) translate3d(0,0,0);
  transform: scale(1.1,1.1) translate3d(0,0,0);
  cursor: pointer;
  box-shadow: 3px 3px 0 0 rgba(0,0,0,.14)
}

.menu-open-button:hover {
  -webkit-transform: scale(1.2,1.2) translate3d(0,0,0);
  transform: scale(1.2,1.2) translate3d(0,0,0)
}

.menu-open:checked+.menu-open-button {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(.8,.8) translate3d(0,0,0);
  transform: scale(.8,.8) translate3d(0,0,0)
}

.menu-open:checked~.menu-item {
  -webkit-transition-timing-function: cubic-bezier(.935,0,.34,1.33);
  transition-timing-function: cubic-bezier(.935,0,.34,1.33)
}

.menu-open:checked~.menu-item:nth-child(3) {
  transition-duration: 180ms;
  -webkit-transition-duration: 180ms;
  -webkit-transform: translate3d(0,-6.2vw,0);
  transform: translate3d(0,-6.2vw,0)
}

.menu-open:checked~.menu-item:nth-child(4) {
  transition-duration: 280ms;
  -webkit-transition-duration: 280ms;
  -webkit-transform: translate3d(6vw,0,0);
  transform: translate3d(6vw,0,0)
}

.menu-open:checked~.menu-item:nth-child(5) {
  transition-duration: 380ms;
  -webkit-transition-duration: 380ms;
  -webkit-transform: translate3d(90.9466px,52.47586px,0);
  transform: translate3d(90.9466px,52.47586px,0)
}

.menu-open:checked~.menu-item:nth-child(6) {
  transition-duration: 480ms;
  -webkit-transition-duration: 480ms;
  -webkit-transform: translate3d(.08361px,104.99997px,0);
  transform: translate3d(.08361px,104.99997px,0)
}

.menu-open:checked~.menu-item:nth-child(7) {
  transition-duration: 580ms;
  -webkit-transition-duration: 580ms;
  -webkit-transform: translate3d(-90.86291px,52.62064px,0);
  transform: translate3d(-90.86291px,52.62064px,0)
}

.menu-open:checked~.menu-item:nth-child(8) {
  transition-duration: 680ms;
  -webkit-transition-duration: 680ms;
  -webkit-transform: translate3d(-91.03006px,-52.33095px,0);
  transform: translate3d(-91.03006px,-52.33095px,0)
}

.menu-open:checked~.menu-item:nth-child(9) {
  transition-duration: 780ms;
  -webkit-transition-duration: 780ms;
  -webkit-transform: translate3d(-.25084px,-104.9997px,0);
  transform: translate3d(-.25084px,-104.9997px,0)
}

#MapBlockPartnerTestVersion {
  height: 39vw;
  position: absolute;
  width: 10vw;
  top: 13.3vw;
  z-index: 0
}

.loader {
  position: absolute;
  width: 15vw;
  left: 5vw;
  top: 20vw
}

.blue,.green,.lightblue,.orange,.purple,.red {
  background-color: #669ae1;
  box-shadow: 3px 3px 0 0 rgba(0,0,0,.14);
  text-shadow: 1px 1px 0 rgba(0,0,0,.12)
}

.blue:hover {
  color: #669ae1;
  text-shadow: none
}

.green,.lightblue,.orange,.purple,.red {
  background-color: #774f9d
}

.green:hover,.red:hover {
  color: #774f9d;
  text-shadow: none
}

.lightblue,.orange,.purple,.red {
  background-color: #f05f45
}

.red:hover {
  color: #fe4365
}

.lightblue,.orange,.purple {
  background-color: #c49cde
}

.orange:hover,.purple:hover {
  color: #c49cde;
  text-shadow: none
}

.lightblue,.orange {
  background-color: #fc913a
}

.orange:hover {
  color: #fc913a
}

.lightblue {
  background-color: #62c2e4
}

.lightblue:hover {
  color: #62c2e4;
  text-shadow: none
}

.credit {
  margin: 24px 20px 120px 0;
  text-align: right;
  color: #eee
}

.credit a {
  padding: 8px 0;
  color: #c49cde;
  text-decoration: none;
  transition: all .3s ease 0s
}

.credit a:hover {
  text-decoration: underline
}

.RefButton {
  cursor: pointer;
  height: 2.5vw;
  width: 3.3vw;
  margin-left: 49%
}

.vertical-menu {
  position: absolute;
  width: 19vw;
  overflow-y: auto;
  left: 12vw;
  height: 7vw
}

.vertical-menu a {
  background-color: #eee;
  color: #000;
  display: block;
  padding: 1vw;
  text-decoration: none
}

.vertical-menu a.active {
  background-color: #744a9b;
  color: #fff
}

.line_1,.line_2,.line_3 {
  animation: load 800ms ease-in-out infinite;
  animation-delay: 100ms;
  background: #744a9b;
  position: relative;
  height: 0.8vh;
  top: 4.1%;
  width: 2.1vw;
  border-radius: 7vw
}

#joincontainer2Partner .footerLinks {
  top: 2vw;
  left: 3vw
}

.line_2,.line_3 {
  animation-delay: 180ms;
  animation: load-r 800ms ease-in-out infinite;
  top: 8.5%;
  left: 0.4vw;
  width: 1.3vw
}

.line_3 {
  animation-delay: 240ms;
  left: 0.6vw;
  top: 15%;
  width: 0.9vw
}

.RefreshButton p {
  font-family: 'montserrat_armstadart';
  font-size: 1.4vw;
  font-weight: 700;
  margin-top: 0.3vw
}

.RefreshButton h1 {
  font-family: 'montserrat_armblack';
  font-size: 3vw
}

.RefreshButton {
  POSITION: RELATIVE;
  LEFT: -9VW;
  top: 30vw
}

.block5 {
  overflow: hidden;
  display: block;
  height: 51vw;
  width: 100vw
}

.PartnersContainer,.block5,.block5contexter {
  position: relative
}

.block5contexter {
  top: -9vw;
  margin-left: auto;
  margin-right: auto;
  height: 38vw
}

.marginLogo {
  margin-left: 50%
}

.Logo_Wrraper:nth-child(0),.Logo_Wrraper:nth-child(1),.Logo_Wrraper:nth-child(2),.Logo_Wrraper:nth-child(3),.Logo_Wrraper:nth-child(4),.Logo_Wrraper:nth-child(5) {
  border-top: none
}

.LogoBlock,.Logo_Wrraper {
  border-top: 1px solid #e8e8e8
}

.Logo_Wrraper {
  margin-top: 1.9vw;
  border-bottom: 1px solid #e8e8e8;
  background: rgba(255,255,255,.7);
  height: 28.1vw;
  width: 100vw
}

.LogoBlock {
  height: 18.9%;
  width: 12.5%;
  background: 0 0;
  border-left: 1px solid #e8e8e8;
  position: relative;
  padding: 2vw;
  float: left;
  box-sizing: unset;
  display: block
}

.LogoBlock img {
  height: 5vw
}

.LogoLink {
  float: left
}

.PartxerOneTextGuest h1 {
  position: relative;
  opacity: 1;
  font-family: 'montserrat_armblack'
}

#ContextBlockGuest,#joincontainer2Partner {
  background-repeat: no-repeat;
  background-size: cover
}

#ContextBlockGuest {
  transition: 0s;
  width: 100vw;
  position: absolute;
  text-align: center;
  height: 100vh;
  background-image: url("/media/src/images/Artboard 47.png");
  background-position: center
}

.PartxerOneTextGuest {
  position: absolute;
  left: 14.8vw;
  font-size: 3.4vw;
  top: 51.8vh
}

#recomendationContextBlock .line {
  top: 5vw;
  transform: rotateZ(-3deg);
  width: 48vw;
  left: 36vw;
  bottom: 3vw;
  background-position: right;
  background-image: url(/media/src/images/mini_line.png);
  background-size: cover
}

.FormContainerPartner {
  position: relative;
  background: 0 0;
  margin-top: 3vw;
  height: 30vw;
  margin-left: 10vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  display: block
}

.FormContainerPartner input,.FormContainerPartner textarea {
  font-size: 1vw;
  color: #fff;
  border: solid transparent;
  background-color: rgba(181,176,174,.6)
}

.FormContainerPartner input {
  height: 0.8vw;
  padding: 1.2vw;
  width: 20vw;
  border-radius: 0.5vw;
  margin-top: 0.5vw;
  position: relative;
  display: block
}

.FormContainerPartner textarea {
  padding: 1vw;
  position: absolute;
  border-radius: 1vw;
  left: 30%;
  margin-top: 0;
  height: 9.5vw;
  width: 40vw;
  resize: none
}

.Block7context .line,.FormContainerPartner form {
  position: relative;
  margin-right: auto;
  margin-left: auto
}

.FormContainerPartner form {
  top: 5.3vw;
  height: 30vw;
  z-index: 11
}

.Block7context .text {
  text-transform: uppercase;
  z-index: 0;
  left: 4vw;
  text-align: top
}

.FormContainerPartner #sendbutton {
  border-radius: 0.5vw;
  cursor: pointer;
  background-color: #774f9d;
  border: #774f9d;
  color: #fff;
  padding-top: 0.6vw;
  height: 2.7vw;
  width: 12.1vw;
  font-size: 1.3vw;
  font-family: 'montserrat_extrabold'
}

#joincontainer2Partner {
  background-image: url(/media/src/images/joner.png);
  height: 43vw;
  background-position: center -10.7vw
}

.Block7context {
  top: -5vw
}

.Block7context div {
  display: block;
  float: left;
  width: 30vw;
  height: 20vw
}

.Block7context h1 {
  z-index: 11;
  color: #000;
  top: 70%;
  font-family: 'montserrat_extrabold';
  font-weight: 700;
  font-size: 3.5vw
}

.contactsUl {
  list-style-type: none;
  position: relative;
  top: 10vw
}

.contactus {
  font-size: 1.4vw;
  position: relative;
  top: 1.3vw;
  margin-left: 0.5vw
}

.Block7context {
  height: 25vw
}

.Block7context .line {
  left: -3vw;
  bottom: -2.5vw;
  height: 30%;
  width: 88%;
  z-index: 0;
  background-image: url("/media/src/images/Artboard 42.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat
}

.Block7context h1,.contactsUl li,.footerLinks a {
  position: relative;

  display: block
}

.contactsUl li {
  width: 45vw;
  height: 5vw;
  margin-top: 0
}

.contacts {
  position: relative;
  left: 10vw
}

#mailicon {
  background-size: 3.4vw;
  background-image: url("/media/src/images/Artboard 41.png")
}

#houseicon {
  background-size: 3.2vw;
  background-image: url("/media/src/images/Artboard 40.png")
}

.footerLinks {
  position: relative;
  top: 0.5vw;
  width: 50vw!important
}

.footerLinks a {
  font-family: 'montserrat_extrabold';
  z-index: 11;
  float: left;
  margin-left: 2vw;
  color: #000;
  text-decoration: none;
  font-size: 1.7vw
}

.bigMenuContainer {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  transition: transform 1s;
  position: fixed;
  top: 0;
  -webkit-transform: translateX(100vw);
  transform: translateX(100vw);
  border-left: 1px solid #f4f6f9;
  opacity: 0.9;
  background-color: #fff;
  z-index: 20;
  height: 100vh;
  width: 35vw
}

.biglinkmenu:hover {
  color: #970c51
}

.biglinkmenu {
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'montserrat_armblack';
  color: #636363;
  font-weight: 700;
  font-size: 3vw
}

.menuUl li {
  margin-top: 4vw
}

.menuUl {
  position: relative;
  top: 8vw;
  list-style-type: none
}

.faq_container {
  position: fixed;
  display: none;
  background-color: #000;
  background: rgba(10,10,10,.3);
  z-index: 20;
  top: 0;
  height: 100vh;
  width: 100vw
}
.faq_block {
  background: #fff;
  position: absolute;
  left: 20vw;
  top: 3vw;
  width: 60vw;
  -webkit-box-shadow: 10px 10px 19px -13px rgba(0,0,0,.75);
  -moz-box-shadow: 10px 10px 19px -13px rgba(0,0,0,.75);
  box-shadow: 10px 10px 19px -13px rgba(0,0,0,.75);
  border-radius: 1.6%;
  overflow: scroll;
  height: 40vw;
  overflow-x: hidden
}

.faq_ul {
  margin-top: 2vw;
  list-style-position: outside;
  display: block;
  position: relative
}

.languageMenuText {
  cursor: pointer;
  /* transition: all 1s; */
  position: absolute;
  text-decoration: none;
  z-index: 11;
  left: 91vw;
  top: 5.7vw;
  font-size: 1.5vw;
  font-family: 'montserrat_armstadart';
  color: #000
}

.faq_ul li {
  background: 0 0;
  display: block;
  text-align: left;
  overflow: hidden;
  margin-top: 2vw;
  margin-left: 2vw
}

.faq_ul>h3 {
  font-family: 'montserrat_armblack';
  display: block;
  font-size: 2vw;
  text-align: center;
  color: #620a6f
}

.faq_ul li h4 {
  cursor: pointer;
  color: rgba(255,120,0,.94);
  font-size: 1.5vw
}

.closefaq {
  position: fixed;
  right: 22vw;
  top: 4.5vw;
  width: 1.9vw;
  height: 2vw;
  z-index: 25;
}

.closefaq:hover {
  cursor: pointer;
  opacity: 0.3
}

.closefaq:after,.closefaq:before {
  position: absolute;
  left: 1vw;
  content: ' ';
  height: 1.8vw;
  width: 0.1vw;
  background-color: #636363
}

.closefaq:before {
  transform: rotate(45deg)
}

.closefaq:after {
  transform: rotate(-45deg)
}
.LoginPageContainer {
  height: 100vh;
  background: url("/media/src/images/Artboard 444a.png");
  background-size: cover;
  background-position: center;
  width: 100vw
}

.LoginFormBlock {
  position: relative;
  z-index: 15;
  width: 24.8vw;
  height: 43%;
  margin-left: 38vw
}

.LoginFormBlock p {
  margin-left: 43.2%;
  font-size: 0.7vw;
  margin-top: 0
}

.LoginInputBlock input::placeholder {
  font-size: 0.7vw;
  color: rgba(255,255,255,.5)
}

.LoginInputBlock input {
  padding-left: 3vw!important;
  height: 6.1%;
  background-repeat: no-repeat;
  padding: 0.9vw;
  color: #ffff;
  width: 20.8vw;
  border: 0;
  border-radius: 4vw
}

.LoginInputBlock {
  height: 2.2vw
}

.LoginBtnBlock {
  POSITION: relative;
  margin-top: 1.5vw;
  cursor: pointer;
  margin-left: 36.6%
}

#password,#username {
  background-image: url("/media/src/images/Artboard 5 (4).png");
  background-size: 100% 100%;
  background-position: 0 0
}

#password {
  background-image: url("/media/src/images/Artboard 6 (2).png")
}

.LoginBtnBlock input {
  font-family: 'montserrat_extrabold';
  FONT-SIZE: 0.9VW;
  TEXT-ALIGN: CENTER;
  cursor: pointer;
  background: #fc5858;
  padding: 0.3VW;
  color: #ffff;
  border: 0;
  width: 5.7vw;
  border-radius: 0.5vw
}

.LoginFormContainer {
  height: 20vw;
  width: 100vw;
  margin-top: 22.9vw;
  position: absolute
}

.closeLogin {
  position: absolute;
  top: 2.5vw;
  z-index: 15;
  right: 8vw;
  width: 2.5vw;
  height: 3vw;
  opacity: 0.3
}

.closeLogin:hover {
  opacity: 1
}

.closeLogin:after,.closeLogin:before {
  position: absolute;
  content: ' ';
  height: 2.5vw;
  width: 0.7vw;
  background-color: #000
}

.closeLogin:before {
  transform: rotate(45deg)
}

.closeLogin:after {
  transform: rotate(-45deg)
}


.school-header{
  background-image: url("/media/src/images/startup-school/Artboard 1.png");
  background-repeat: no-repeat;
  background-position: center 55px;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
header{
  border-bottom: 1px solid #f4f6f9;
  height: 100px;
  color: black;
  position: fixed;
  z-index: 20;
  background: white;
  width: 100%;
  padding: 0 105px 0 105px;
}
.h_context{
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.h_context ul{
  list-style-type: none;
  color: #94948d;
  display: flex;
}
.h_context ul a{
  color: #282827;
  font-weight: bold;
  font-family: 'montserrat_armstadart';
  font-size: 18px;
  text-decoration: none;
  /* font-weight: 500; */
}
.h_context ul li{
  margin-left: 24px;
}
.s_logo{
  height: 67px;
  cursor: pointer;
  width: 65px;
}
.s_logo img{
  max-width: 100%;
  height: 100%;

  /* height: 151.26px; */
  /* width: 98.19px; */
}
.m_language{

}
.m_language div{
  width: 35px;
  height: 5px;
  border-radius: 20px;
  background-color: #282827;
  margin: 5px 0;
}
.m_language{
  margin-left: 45px;
  cursor: pointer;
  margin-right: 27px;
}
.school-header h1{
  color: white;
  position: relative;
  text-transform: uppercase;
  z-index: 11;
  /* top: 50%; */
  font-family: 'akrobatBlck';
  text-align: center;
  font-size: 110px;
}
.school-header-wrapper{
  /* margin-bottom: 352px; */
}
.l_text{
  color: #282827;
  font-family: 'montserrat_armstadart';
  /* font-weight: bold; */
  text-decoration: none;
  font-size: 26px;
}
.mfk{
  align-items: center;
  display: flex;
}
.what_sec{
  background-image: url("/media/src/images/startup-school/Artboard 1 copy.png");
}
.s_sub_cont{
  width: 100%;
  overflow: hidden;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.what_sec h2{
  display: flex;
  z-index: 15;
  color: #282827;
  font-family: 'akrobatBlck';
  /* float: right; */
  font-size: 140px;
  font-weight: bold;
  text-transform: uppercase;
  /* margin-top: 431px;
  margin-right: 257px; */
}
.s_dis_block{
  max-width: 555px;
  width: fit-content;
  position: relative;
  float: right;
  z-index: 15;
  margin-top: 39vh;
  margin-right: 13.38541666666667vw;
}
#arm_d::after{
  opacity: 0 !important;
}
.s_dis_block::after{
  content: "?";
  text-transform: uppercase;
  color: #282827;
  font-family: 'akrobatRegular';
  font-weight: bold;
  position: absolute;
  font-size: 285px;
  display: flex;
  left: 100%;
  top: 14px;
  line-height: 0.8;
}
.dubText{
  width: fit-content;
  position: relative;
  bottom: 32px;
  font-size: 54px;
  left: 24px;
}
.what_sec h3{
  text-transform: uppercase;
  color: #282827;
  font-family: 'akrobatBlck';
  font-weight: bold;
  background-color: #FFC83E;
}
.s_idea{
  background-image: url("/media/src/images/startup-school/Artboard 1 copy 2.png");
}

.s_idea h3{
  /* transition: all 2s; */
  display: flex;
  font-family: 'akrobatBlck';
  font-variation-settings: "wdth" 600, "wght" 200, "opsz" 48;
  color: black;
  text-align: right;
  max-width: 55%;
  position: relative;
  z-index: 15;
  font-weight: bold;
  text-transform: uppercase;
  float: right;
  font-size: 53px;
  margin-top: 13.69791666666667vh;
  margin-right: 8.229166666666667vw;
}

.s_cop{
  background-image: url("/media/src/images/startup-school/Artboard 1 copy 3.png");
}
.s_cop h3{
  display: block;
  position: relative;
  z-index: 15;
  font-family: 'akrobatRegular';
  color: black;
  text-align: right;
  max-width: 44%;
  font-weight: normal;
  text-transform: uppercase;
  float: right;
  font-size: 38px;
  margin-top: 11vh;
  margin-right: 5vw;

}
#sss_to_1 h3{
  margin-top: 35vh;
}
.s_cop h3 b{
  color: black;
  font-family: 'akrobatBlck';
  margin: 0 5px 0 5px;
}
.bgr{
  background-image: url("/media/src/images/startup-school/Artboard 1 copy 4.png");
}
.bgr2{
  background-image: url("/media/src/images/startup-school/Artboard 1 copy 5.png");
}
.bgr4{
  background-image: url("/media/src/images/startup-school/Artboard 1 copy 6.png");
}
.bgr5{
  background-image: url("/media/src/images/startup-school/Artboard 1 copy 7.png");
}
.bgr2 h3{
  /* max-width: 650px !important; */
}
.bgr4 h3{
  /* max-width: 733px; */
}
.bgr5>h3{
  max-width: 56vw;
  margin-top: 30vh;
}
.menuUl{
  text-align: center;
}
.menuUl li a{
  /* font-size: 53px; */
}
.slider_text_block h3{
  margin-top: 2%;
  font-size: 25px;
  max-width: 46%;
}
.slider_text_block{
  width: 67%;
  /* display: inline-block; */
  float: right;
}
.slider_text_block h3:nth-child(1){
  position: relative;
  float: right;
  display: block;
  width: 50%
}
.slick-list{
  width: 100%;
  position: relative;
  /* top: -8vw; */
}
#c_lang_arm{
  font-family: sans-serif;
}
#its_arm{
  left: 10px;
  font-size: 35.2px;
  bottom: 29px;
}
.its_arm{
  bottom: 30px;
  font-size: 50px;
  left: 9px;
}
#sdasd{
  max-width: 62vw;
  font-size: 28px;
  font-family: 'montserrat_armstadart';
}
#bdx{
  font-family: 'montserrat_armblack';
  font-size: 73px;
}
#haye{
  font-size: 28px;
}
#armer{
  font-size: 40px;
}
#hjk{
  font-size: 28px;
}
#hjdk{
  font-size: 28px;
}
#hjdkd{
  font-size: 27px;
}

.fadeIn {
  -webkit-animation: FadeIn 0.9s linear forwards;
  animation: FadeIn 0.9s linear forwards;
  opacity: 0;
}

@-webkit-keyframes FadeIn {
50% { opacity: 1; }
}

@keyframes FadeIn {
100% { opacity: 1; }
}


.fadeOut {
  -webkit-animation: FadeOut 0.9s linear forwards;
  animation: FadeOut 0.9s linear forwards;
  opacity: 1;
}

@-webkit-keyframes FadeOut {
50% { opacity: 0; }
}

@keyframes FadeOut {
100% { opacity: 0; }
}