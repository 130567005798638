@font-face {
    font-family:'montserrat_armblack';src:url(/media/src/fonts/webfonts2/Montserratarm-Black.woff2);font-weight:400;font-style:normal
  }
  
  @font-face {
    font-family:'montserrat_armstadart';src:url(/media/src/fonts/webfonts2/Montserratarm-Regular.woff2);font-weight:400;font-style:normal
  }
  
  @font-face {
    font-family:'montserrat_extrabold';src:url(/media/src/fonts/webfonts2/Montserratarm-ExtraBold.woff2);font-weight:400;font-style:normal
  }
  
  @font-face {
    font-family:'montserrat_extralight';src:url(/media/src/fonts/webfonts2/Montserratarm-ExtraLight.woff2);font-weight:400;font-style:normal
  }
  
  @font-face {
    font-family:'montserrat_extralight2';src:url(/media/src/fonts/webfonts2/Montserratarm-ExtraLight.woff2);font-weight:400;font-style:normal
  }
  @font-face {
    font-family:'akrobatRegular';
    src:url(/media/src/fonts/akrobat/Akrobat-Regular.woff);font-weight:400;font-style:normal
  }
  @font-face {
    font-family:'akrobatBlck';
    src:url(/media/src/fonts/akrobat/Akrobat-Black.woff2);font-weight:400;font-style:normal
  }
