
.SquareFix{
  top: 37.9%;
  left: 68%;
}

#Mypetaq1pet{
  top:-5.5%;
  left: 3.7%;
}
#Mypetaq2pet{
  top: 49.7%;
  left: 60.5%;
}
#Mypetaq3pet{
  background: none;
  top: 23.8%;
  left: 28%;
}

#Mypetaq5pet{
  top: 23.6%;
  left: 60.5%;
}
#Mypetaq4pet a{
  font-size: 1vw;
}
#Mypetaq1pet a, #Mypetaq3pet a{
  font-size: 0.9vw;
}
#Mypetaq5pet a{
  font-size: 1.3vw;
}
#Mypetaq4pet{
  background: none;
  top: 4.3%;
  left: 44.2%;
}
#resBackButton {
  top: 65.6vh;
}


#Mypetaq1pet .LinkContainercitizen{
  
}