
#Mypetaq11{
  top: 4.1%;
  left: 11.8%;
}
      #Mypetaq44 a{
        font-size: 0.9vw;
      }
      #Mypetaq55 a{
        font-size: 2vw;
      }
      #Mypetaq33 a,#Mypetaq22 a{
        font-size: 1.3vw ;
      }
#devback {
  top: 65.6vh;
}
#Mypetaq22 .LinkContainerdev{
  top: 49%;
}
#Mypetaq55 .LinkContainerdev{
  top: 50%;
}
#Mypetaq44 .LinkContainerdev{
  top: 50%;
}
#Mypetaq11 .LinkContainerdev{
  top: 49%;
}
#Mypetaq22{
  top: 43.1%;
  left: 60.5%;
}
#Mypetaq33 .LinkContainerdev{
  top: 52%;
}
#Mypetaq33{
  background: none;
  left: 3.8%;
  top: -5.6%;
}
#Mypetaq55{
  top: 23.5%;
  left: 60.5%;
}
#Mypetaq44{
  background: none;
  top: 33.4%;
  left: 52.3%;
}
