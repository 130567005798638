.bottomanim{
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
     transform: rotateX(180deg);
      -webkit-animation-delay: 1.5s;
     animation-delay: 4s;
  }
.topAnim {
    /* -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
     transform: rotateX(180deg);
      -webkit-animation-delay: 1.5s;
     animation-delay: 0.1s;
      */
     transform: rotateY(-180deg);
     -webkit-animation-delay: 1s;
     animation-delay: 1s;
}
.leftopAnim {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.lefbottomAnim {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-animation-delay: 2s;
    animation-delay: 4s;
}

.rightbottomAnim {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-animation-delay: 2s;
    animation-delay: 4s;
}
.rightopAnim{
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-animation-delay: 2s;
    animation-delay: 4s;
}