
#Mypetaq1car{
  left: 44.3%;
}
#Mypetaq2car{
  top: 33.4%;
  left: 36.1%;
}


#Mypetaq2car .LinkContainer{
  top: 51%;
}