
#Mypetaq1{
  left: 44.3%;
}
#Mypetaq2{
  top: 33.4%;
  left: 36.1%;
}


