@font-face {
  font-family: 'montserrat_armblack';
  src: url(/media/src/Components/Cricolums/Engine/styles/montserratarm-black-webfont.woff2) format('woff2'),
       url(/media/src/Components/Cricolums/Engine/styles/montserratarm-black-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}
.ClickBlock{
  /* background: red;  */
  /* background: red; */
  height: 28%;
  position: absolute;
  width: 63%;
  cursor: pointer;
  z-index: 1;
  /* margin-top: 4vw; */
  top: 40.5%;
  left: 18.4%;
}
/* фон */
#root{
  height: 100%;
  /* overflow: hidden; */
  width: 100%;
  /* background-image: url('../img/fon/background.png'); */
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  /* background: none; */
}
  .MainContainer{
    height: 100%;
    width: 100%;
    /* border: 1px solid blue; */
  }
  .PetaqBlock{
    position: absolute;
    width: 37%;
    height: 65%;
    top: 4.3%;
    left: 28%;
    /* border: 3px solid purple; */
  }
  .Square{
    
    background-image: url("/media/src/Components/Cricolums/Engine/img/menu/Artboard 7.png");
    background-size: 112% 112.2%;
    background-position: center;
  }


  .LanguageButton{
    z-index: 99;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 1s;
    text-align: center;
    position: absolute;
    height: 23.9%;
    width: 12.7%;
    /* border: 1px solid darkcyan; */
    top: -11.8%;
    left: 85%;

    transform: none;
    background-image: url("/media/src/Components/Cricolums/Engine/img/menu/Artboard 8 (3).png");
    background-size: 102.4% 116%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .LanguageButton .ClickBlock{
    top: 55%;
  }
  .LanguageButton span{
    transition:  opacity 0.30s;
      position: relative;
      top: 14.7vh;
      cursor: pointer;
      font-weight: bold;
      font-family:'montserrat_armstadart';
      font-size: 1.5vw;
      background: none;
      color: #2E2F2F;
     text-decoration: none;
  }
  /* //////////////// */

  .BackButton{
    z-index: 99;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 1s;
    text-align: center;
    position: absolute;
    height: 23.9%;
    width: 12.7%;
    /* border: 1px solid darkcyan; */
    top: 65.6%;
    left: 3.8%;
    transform: none;
    background-image: url("/media/src/Components/Cricolums/Engine/img/menu/Artboard 8 (3).png");
    background-size: 102.4% 116%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .BackButtonLogo .ClickBlock{
    height: 28%;
    position: absolute;
    width: 63%;
    cursor: pointer;
    z-index: 1;
    /* margin-top: 4vw; */
    /* top: 40.5%; */
    /* left: 18.4 !important%; */
    top: 55.1%;
    left: 18.9%;
  }
  .BackButtonLogo{
    z-index: 99;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 1s;
    text-align: center;
    position: absolute;
    height: 23.9%;
    width: 12.7%;
    /* border: 1px solid darkcyan; */
    /* top: 65.6%; */
    top: -11.9%;
    left: 3.9%;
    transform: none;
    background-image: url("/media/src/Components/Cricolums/Engine/img/menu/Artboard 6 copy.png");
    background-size: 102.4% 116%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .BackButton span{
    transition:  opacity 0.30s;
      position: relative;
      top: 10vh;
      cursor: pointer;
      font-weight: bold;
      font-family:'montserrat_armblack';
      font-size: 2.5vw;
      background: none;
      color: #2E2F2F;
     text-decoration: none;
  }

  .Square, .centerSquare{
    opacity: 0;
    transition: all 0.50s;
    margin: 0;
    padding: 0;
    position: absolute;
    height: 33%;
    width: 31%;
    /* border: 1px solid darkcyan; */
    background-repeat: no-repeat;
  }
  .centerSquare{
    left: 24%;
    /* border: 1px solid darkcyan; */
    top: 37%;
    background-image: url("/media/src/Components/Cricolums/Engine/img/menu/Artboard 8.png");
    background-size: 110.6% 131%;
    background-position: center;
  }
  .RightTopSquare{
    top: 22.7%;
    left: 2.2%;
  }
  .TopSquare{
    left: 24%;
    top: 7.7%;
  }
  .RightBottomSquare{
    top: 52.5%;
    left: 2.1%;
  }
  .BottomSquare{
    top: 67.4%;
    left: 24.1%;
  }
  .LeftTopSquare{
    left: 46%;
    top: 22.7%;
  }
  .LeftBottomSquare{
    left: 46%;
    top: 52.6%;
  }
  .centerlink{
    text-transform: uppercase;
    /* border:1px solid black; */
    transition: opacity 0.30s;
    cursor: pointer;
    z-index: 99;
    /* left: 29.0%; */
    /* border: 1px solid darkcyan; */
    /* top: 40.0%; */
    display: block;
    /* padding-top: 20%; */
    /* padding-bottom: 20%; */
    font-weight: bold;
    font-family:'montserrat_armblack';
    font-size: 1.1vw;
    background: none;
  
    /* text-align: center; */
    color: #2E2F2F;
    text-decoration: none; 
  }
  .LinkContainercitizen{
    transition: opacity 0.30s;
    background-color: none;
   opacity: 0;
    z-index: 99;
    width: 25%;
    text-align: center;
    position: relative;
    /* height: 30%; */
    /* border: 1px solid black; */
    top: 32vh;
    left: 27.1%;
    /* background: none; */
  }
  .LinkContainerdev{
    transition: opacity 0.30s;
    background-color: none;
   opacity: 0;
    z-index: 99;
    width: 25%;
    text-align: center;
    position: relative;
    /* height: 30%; */
    /* border: 1px solid black; */
    top: 30.5vh;
    left: 27.1%;
    /* background: none; */
  }
  .LinkContainer{
    transition: opacity 0.30s;
    display: none;
    z-index: 99;
    width: 25%;
    text-align: center;
    position: relative;
    /* height: 30%; */
    /* border: 1px solid black; */
    top: 31.9vh;
    left: 27.1%;
    background: none;
  }
  .sqnone{
    opacity: 0 !important;
  }
