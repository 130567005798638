
#text1 .bigtext>div>span{
    transform-origin: center 35.7262vw !important;
  }
  #text2 .bigtext>div>span{
    transform-origin: center 35.7262vw !important;
  } 

  #text3 .bigtext>div>span{
    transform-origin: center 53.7262vw !important;
  }
  /* ///////////////// */
  
  #text4 .bigtext>div>span{
    transform-origin: center 57.7262vw !important;
  }
  /*  */
  #text5 .bigtext>div>span {
    transform-origin: center 40.7262vw !important;
  } 
  /* /////////////////////// */
  #text6 .bigtext>div>span{
    transform-origin: center 35.7262vw !important;
  }
  

  