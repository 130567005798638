.DonateFormConatiner{
    position: fixed;
    
    display: none;
    top: 0vh;
    background-color: black;
    background: rgba(10, 10, 10, 0.3);
    z-index: 100;
    height: 100vh;
    width: 100vw;
}
.DonateFormBlock > div{
    float: left;
}
.DonateFormBlock{
    /* position: fixed; */
    position: relative;
    top: -0vh;
    overflow: hidden;
    background-color: #ffffff;
    /* background-position: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/media/src/Components/Donate/images/donatee (1).png");
    /* z-index: 6666; */
    height: 41vw;
    opacity: 1;
    /* left: 12vw; */
    margin-top: 2vw;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 65vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.DonateText {
    position: relative;
    overflow: hidden;
    /* background-color: red; */
    height: 41vw;
    /* left: 1.4vw; */
    width: 20vw;
}
.DonateText h2{
    position: relative;
    left: 0vw;
    font-family: 'montserrat_extrabold';
    font-size: 1.4vw;
    margin-top: 1.5vw;
    margin-bottom: 1vw;
    /* font-size: 1vw; */
    color: #ffffff;
}
.DonateText p{
    position: relative;
    margin-bottom: 1vw;
    font-size: 1.1vw;
    left: 3vw;
    width: 14vw;
    color: #ffffff;
}
.DonateFormDiv h1{
    position: absolute;
    font-weight: bold;
    font-family: 'montserrat_armblack'; /*extrabolder*/
    font-size: 1.1vw;
    color: #970C51;
    top: 3vw;
    left: 6vw;
}

.DonatForm h1{
    position: absolute;
    font-weight: bold;
    font-family: 'montserrat_armblack';
    font-size: 1.1vw;
    color: black;
    top: 24.1vw;
    left: 6vw;
}


.inputBlock{
    /* width: 10vw; */
    /* background-color: red; */
    display: inline-block;
}
.DonatForm{
    /* background-color: red; */
    padding-left: 6vw;
    height: 48vw;
    width: 45vw;
}
/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 1vw;
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 1vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .containerOther {
    display: block;
    position: relative;
    padding-left: 1vw;
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 1vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  #cardinp{
    margin-top: 5.6vw;
  }
  .donateButton{
    background-color: #ffffff;
    border: none;
    font-size: 2vw;
    font-family: 'montserrat_armblack';
  }
  .donateButton{
      cursor: pointer;
  }
  #dnt-blck-button{
      margin-top: 1vw;
      margin-left: 9vw;
  }
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .containerOther input {
    position: absolute;
    top: -0.4vw;
    left: 0vw;
    /* z-index: 11; */
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.6vw;
    width: 0.6vw;
    background-color: #eee;
    border: 0.1vw solid #C4C4C4;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  /* .container:hover input ~ .checkmark {
    background-color: #ccc;
  } */
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #940C53;
    border: 0.1vw solid #940C53;
  }

  .container input:checked ~ .donatePrices {
    font-weight: bold;
    color: #940C53;
    font-family: 'montserrat_extrabold';
    /* border: 0.1vw solid #940C53; */
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  /* .container input:checked ~ .checkmark:after {
    display: block;
  } */
  
  /* Style the indicator (dot/circle) */
.donatePrices{
    font-family: 'montserrat_armstadart';
    color: #606060;
    /* font-weight: bold; */
    position: relative;
    top: -0.3vw;
}
.DonatForm input{
    width: 12vw;
    height: 0.8vw;
    display: block;
    padding: 0.4vw;
    border: 0.1vw #E8E8E8 solid;
}
.inputBlock{
    position: relative;
    margin-right: 7vw;
    margin-bottom: 0.5vw;
    float: left;
    top: 6vw;
}
.RadioBlock{
    position: relative;
    display: block;
    top: 10vw;
    /* margin-right: 7vw; */
    /* margin-bottom: 1.2vw; */
    float: left;
    /* bottom: 6vh; */
}
#other{
    width: 4.5vw;
}
.RadioBlock label{
    width: 4vw;
    float: left;
    margin-left: 0.5vw;
}
/* //crest */
.closeForm {
    position: absolute;
    right: 2vw;
    top: 1.9vw;
    width: 32px;
    height: 32px;

    }
    .closeForm:hover {
    opacity: 0.3;
    }
    .closeForm:before, .closeForm:after {
    position: absolute;
    left: 1vw;
    content: ' ';
    height: 1.4vw;
    width: 0.1vw;
    background-color: #636363;
    }
    .closeForm:before {
    transform: rotate(45deg);
    }
    .closeForm:after {
    transform: rotate(-45deg);
    }
#adressinputdon{
    width: 32vw;
}
#adressinputdon2{
    width: 32vw;
}
.WidthInput{
    width: 32vw;
}

.DonatForm .inputBlock label > SPAN{
    color: #A1A1A1;
    margin-bottom: 0.4vw;
    font-weight: bold;
    font-size: 0.6vw;
    font-family: 'montserrat_armstadart';
    display: block;
    text-align: left;
}
.DonateFormDiv{
    position: relative;
    height: 48vw;
    width: 45vw;
    /* background-color: red; */
}
.inputBlock > label:focus-within .inptext {
    /* font-size: 0.5vw; */
    font-family: 'montserrat_armblack';
  color: #940C53 ;
}