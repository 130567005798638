
.Zona{
    opacity: 0;
    transition: all 0.30s;
    /* pointer-events: none; */
}
.MapContainer{
    position: relative;
    top: 2.3vw;
    /* background: red; */
    width: 100vw;
}


.sign .YellowSign{
    position: relative;
    transition: all 0.10s;
    
    z-index: 100;
    width: 3.4vw;
}
.Marzer img.colorimg{
    transition: all 0.30s;
}
.Zona{
    background: none;
}
.MarzDiscription> .linedes{
    content:"";
    position: absolute;
    bottom: 0;
    /* left: 3vw; */
    /* z-index: 100; */
    left: -3vw;
    width: 8vw;
    right:0;
    height: 1.4vw;
    border-top: 0.1vw solid #5E5E5E;
    z-index: -1;
}
.MarzDiscription {
    padding: 1vw;
    position: absolute;
    display: none;
    transition: all 0.1s;
    /* z-index: 10000; */
    box-sizing: unset;
    border-radius: 5%;
    box-shadow: 10px 10px 13px -8px rgba(0,0,0,0.75);
    background-color: #ffffff;
    z-index: 11;
    height: 9vw;
    left: 5vw;
    top: -7vw;
    width: 25vw !important;
}
.MarzDiscription h4{
    font-size: 1.4vw;
    color: #5E5E5E;
    font-family: 'montserrat_extrabold';
    font-weight: bold;
}
.MarzDiscription p{
    margin-top: 0.5vw;
    font-size: 0.9vw;
    font-family: 'montserrat_armstadart';
}
.marznametext{
    font-size: 1.1vw;
    float: left;
    font-family: 'montserrat_armstadart';
    position: absolute;
    top: 1.2vw;
    left: 13vw;
}
.PasiveSignSign{
    position: absolute;
    right: -0.8vw;
    width: 4.4vw;
    top:-0.4vw;
    /* transition: all 1s; */
    z-index: 5;
}
#gegharkunik .sign{
    position: absolute;
    top: 20%;
    left: 10%;
    background: none;
}
    #gegharkunik{
        top: 24%;
        left: 35.5%;
        width: 30.2%;
        height: 32.7%;
        background-color: none;
        /* border: 1px solid black; */
    }
    .f1{
        /* margin-left: 3vw !important; */
    }
    .f2{
        /* margin-left: 5.3vw !important; */
    }
    .f3{

    }
    .sdis p{
        width: 20vw;
        margin: 0;
    }
    .sdis{
        /* position: absolute; */
        position: absolute;
    /* background: red; */
    top: 5.5vw;
    padding-left: 9.8vw;
    /* width: 100%; */
    text-align: left;
    }
    .det{
        font-weight: bold;
        position: relative;
        left: -0.5vw;
    }
    .mdisDis{
        position: relative;
        
    top: -0.4vw;
    }
    #gegharkunik img.colorimg {
        /* opacity: 1; */
        left: -12.7%;
    margin-top: -19.5%;
    width: 128%;
    height: 16.4vw;
    position: relative;
    }

#aragatsotn .sign{
    position: absolute;
    top: 2%;
    left: 60%;
    background: none;
}
#kotayq .sign{
    position: absolute;
    top: 33%;
    left: 11%;
    
    background: none;
}
#tavush .sign{
    position: absolute;
    top: -3%;
    left: 33%;
    background: none;
    
    /* background: none; */
}
#tavush #sign2{
    position: absolute;
    top: 30.1%;
    left: 52%;
    background: none;
}
#kotayq{
    /* opacity: 0; */
    top: 25%;
    width: 15.4%;
    height: 23.3%;
    left: 25.5%;
}
#kotayq img.colorimg{
    margin-top: -43.6%;
    width: 138%;
    height: 13vw;
    right: 18.5%;
    position: relative;
}
#aragatsotn img.colorimg{
    position: relative;
    margin-top: -15%;
    right: -12.9%;
    width: 93%;
    height: 11.7vw;
}
#aragatsotn #sign2{
    position: absolute;
    top: 44.1%;
    left: 60%;
    background: none;
}
#aragatsotn{
    height: 22%;
    width: 29%;
    left: 0.5%;
    top: 23%;
}
#meuLocUl{
    left: 4vw;
}
.LocationBorder {
    position: relative;
    background-image: url("/media/src/Components/Locations/img/Background/Artboard 6.png");
    height: 100%;
    width: 26%;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 100% 115%;
    /* background: red; */
}
.LocationImage {
    position: absolute;
    background: gold;
    height: 100%;
    /* float: left; */
    left: 8.4vw;
    /* background-image: url('../img/Background/Artboard 5 (3).png'); */
    background-size: contain;
    background-repeat: no-repeat;
    width: 72%;
}
.LocationImageContainer {
    display: block;
    overflow: hidden;
    opacity: 1;
    transition: all 0.1s;
    z-index: 0;
    bottom: 0;
    position: fixed;
    /* top: 20.1vw; */
    height: 20.6vw;
    left: 10vw;
    width: 32vw;
    /* background-color: yellow; */
    /* background-image: url(../img/Background/Artboard 5 (3).png); */
}
.LocationImageContainer div{
    /* display: inline; */
    float: left;
}
#locmenunav{
        /* background-color: #fff; */
        /* transform: translateZ(0); */
        /* transition: transform 1s; */
        border-bottom: none;
        text-align: center;
        position: fixed;
        padding-left: 7vw;
        /* transform: translateY(-15vw); */
        /* top: 12vw; */
        z-index: 11;
        height: 10vw;
        /* border: 1px solid red; */
        background: none;
        width: 100vw;
}
/* #gegharh1imgct_1{
    /* background: grey; */



#tavush {
    background: none;
    top: 1%;
    width: 21.9%;
    height: 26.5%;
    left: 34.1%;
    /* border: 1px solid black; */
    /* border: 1px solid black; */
}
.Marzer{
    /* overflow: hidden; */
    transition: all 0.10s;
    opacity: 1;
    background: none;
    /* border: 1px solid black; */
    position: absolute;
}

/* //images */
#tavush img.colorimg{
    width: 114.6%;
    position: relative;
    margin-top: -9.6%;
    right: 9%;
    height: 12.8vw;
}

.MapBlock{
    z-index: 11;
    position: relative;
    background-repeat: no-repeat;
    /* background-color: yellow; */
    left: 32vw;
    top: 5vw;
    width: 42vw;
    height: 39vw;
    background-size:42.5vw 39.6vw;
    background-image: url("/media/src/Components/Locations/img/Map/Artboard 18.png");
}
#end_left{
    top: 34%;
    left: 0%;
    width: 32vw;
}
#end_left img{
    width: 19.6%;
}
#left_right{
    left: 16.7%;
    width: 27vw;
    top: 34.6%;
}
#left_right img{
    width: 21.8%;
}
#end_right{
    top: 24.9%;
    left: 63.2%;
}
#end_right img{
    width: 86%;  
}
.element_1{
    /* background: red; */
}
#bottomPathContgtainer {
    /* opacity: 1; */
    /* transition: all 1s; */
    position: absolute;
    top: 84.3%;
    left: 18.1%;
}
#center_right_right{
    top: 26%;
    left: 63.8%;
}
#center_right_right img{
    width: 80%;
}
#center_right{
    left: 33%;
    top: 26.2%;
}
#center_right img{
    width: 69%;   
}
#bottomPathContgtainer img{
    width: 69%;
 }
/*  */
#leftBottom{
    width: 31.3vw;
    top: 84%;
}
#center_top {
    left: 19%;
    top: 38.9%;
}
#center_top img{
    width: 74%;
}
#leftBottom img{
    width: 21%;
}
.abs{
    transition: opacity 0.5s;
    opacity: 0;
    position: absolute;
}