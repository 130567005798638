.slick-dots li button:before {
    font-size: 1vw !important;
    line-height: 20px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 20px !important;
    height: 20px !important;
    content: '•' !important;
    text-align: center !important;
    opacity: .25 !important;
    color: #774F9D !important;
  }
  .slick-dots li {
    position: relative;
    /* background: red; **/
    display: inline-block;
    width: 1.2vw;
    height: 1.3vw;
    margin: 0 0;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots .slick-active button::before{
    font-size: 1vw !important;
      line-height: 20px !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 20px !important;
      height: 20px !important;
      content: '•' !important;
      text-align: center !important;
    color: #774F9D !important;
    opacity: 1 !important;
  }
  .slick-slider {
    z-index: 11;
    top: 7vw;
  }
  .slick-next:before, .slick-prev:before {
    font-size: 3vw !important;
    line-height: 1 !important;
    opacity: .75 !important;
    color: #FDC248 !important;
  }
  .slick-dots {
    bottom: 1vw;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .Slick-item-rec-Block div{
    float: left;
  }
  .Slick-item-rec-container{
  position: relative;
  top: -5vw;
  }
  .Slick-item-container .slick-dots{
    bottom: 0;
  }
  .Slick-item-container .slick-slider {
    z-index: 0;
    top: 0vw;
  }
  
  .Slick-item-container .slick-slide img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .slick-dots {
    bottom: 1vw !important;
    left: -10vw;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-slider {
    z-index: 11;
    top: 7vw;
  }  
  .slick-prev {
    left: -3.8vw !important; 
  }
  .block5 .slick-prev, .block5 .slick-next{
    left: -3.8vw !important;
    background-color: red;
    display: none !important;
  }