
@media screen and (orientation: portrait){
/* , all and (max-width: 1200px) */
  html, body {
    position: relative;
    height: 100%;
  }
      body {
        background: #eee;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        /* font-size: 14px; */
        color:#000;
        margin: 0;
      padding: 0;
      }
      #tree_1_container{
        background-image: url("/media/src/images/MobileVersion/Artboard 74.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center;
      }
      #tree_3_container{
        background-image: url("/media/src/images/MobileVersion/Artboard 75 (1).png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center;
      }
      #home{
          background-image: url("/media/src/images/MobileVersion/Artboard 52 (3).png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          background-position: center;

      }
      .sketch{
        display: none!important; 
        z-index: 0 !important;
      }
      #startupend{
        background-image: url(/media/src/images/MobileVersion/startupend.jpg);
        background-size: 100% 100%; 
                background-repeat: no-repeat;
                     overflow: hidden;

    }




  
  
  /*slider styles*/
  .rgbShiftSlider {
    display: block;
    position: absolute;
    left: 31%;
    top: 46%;
    /* mix-blend-mode: hard-light; */
    opacity: 0.85;
    width: 39vw;
    height: 10%;
    overflow: hidden;
    /* transform-origin: 50vw 300vh; */
    transform-origin: 50% 50%;
}
  canvas {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
  }
  
  nav {
      position: absolute;
      top: 4vw;
      left: 4vw;
      z-index: 1;
  }
  

      #partnerspage{
        background-image: url(/media/src/images/MobileVersion/partners.png);
        background-size: 100% 100%;
                background-position: center;
                     overflow: hidden;

    }
    .VideoBlock1_5 iframe{
      width: 100%;
      height: 100%;
    }
    .VideoBlock1_5{
      /* overflow: hidden; */
      /* border: 1px solid red;s */
      overflow: hidden;
      height: 15.9%;
      width: 50.9%;
      top: 36.7%;
      position: absolute;
      left: 23.7%;
    }
    .Light{
      transform: rotate(5deg);
      position: absolute;
      /* position: absolute; */
      height: 32%;
      width: 73.1%;
      top: 20.7%;
      transition: opacity 50ms;
      /* transition: opacity ; */
      left: 6.2%;
      background-image: url(/media/src/images/MobileVersion/light.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    #home_1_1_container{
      background-image: url("/media/src/images/MobileVersion/robot/Artboard 59 (2).png");
      background-size: 100% 100%;
              background-repeat: no-repeat;
                   overflow: hidden;
        background-position: center;
    }
    #video_1_5{
      background-image: url(/media/src/images/MobileVersion/vtest.png);
      background-size: 100% 100%;
              background-repeat: no-repeat;
                   overflow: hidden;
        background-position: center;
    }
    /* :nth-child(2) */
    #top_block .Conceptdis{

      /* color: white; */
      display: block;
      height: 100%;
      /* border: 1px solid red; */
      color: white;
      font-size: 3vw;
      font-family: 'montserrat_armstadart';
      font-weight: bold;
      position: relative;
      padding-left: 16.1%;
      top: 11%;
  
    }
    #top_block .Conceptdis:nth-child(1){
      padding-left: 16.5%;
      padding-top: 1.2%;
    }
    #top_block .Conceptdis:nth-child(2){
      padding-left: 14.8%;
      padding-top: 1.2%;
    }
    #top_block .Conceptdis:nth-child(3){
      padding-left: 17.4%;
      padding-top: 1.3%;
    }
    #top_block .Conceptdis p{
      font-size: 6.5vw;
      font-family: 'montserrat_extralight';
      position: absolute;
      top: 41.3%;
      left: 66%;
    }

    #bg3{
      top: 41.3%;
      left: 64% !important;
    }
    #bg2{
      left: 62% !important;
    }
    #bottom_block .Conceptdis p{
      font-size: 6.5vw;
      font-family: 'montserrat_extralight';
      position: absolute;
      top: 33.3%;
      left: 56%;
    }
    #bg{
      top: 34.3% !important;
      left: 65% !important;
    }
    #bottom_block .Conceptdis:nth-child(1){
      padding-left: 12.3%;
      padding-top: 1.2%;
    }
    #bottom_block .Conceptdis:nth-child(2){
      padding-left: 11.4%;
      padding-top: 1.3%;
    }
    #bottom_block .Conceptdis{

      /* color: white; */
      color: white;
      height: 100%;
      font-size: 3vw;
      font-family: 'montserrat_armstadart';
      font-weight: bold;
      position: relative;
      padding-left: 12.1%;
      top: 0.9%;  
    }
    .RaketaStart{
      position: absolute;
      top: 69.7%;
      transition: top 1s;
      left: -4%;
      height: 30%;
      width: 39%;
      z-index: 5;
      background-image: url("/media/src/images/MobileVersion/Artboard 69.png");
      background-size: 49%;
              background-repeat: no-repeat;
                   overflow: hidden;
        background-position: center;
    }
    .Raketa{
      position: absolute;
      top: 150%;
      /* transition: top 1s; */
      left: -4%;
      height: 30%;
      width: 39%;
      z-index: 5;
      background-image: url("/media/src/images/MobileVersion/Artboard 69.png");
      background-size: 49%;
              background-repeat: no-repeat;
                   overflow: hidden;
        background-position: center;
    }
    #Home_1_3_Container{
      background-image: url("/media/src/images/MobileVersion/Artboard 63.1.png");
      background-size: 100% 100%;
              background-repeat: no-repeat;
                   overflow: hidden;
        background-position: center;
    }
      #statup1{
        background-image: url(/media/src/images/MobileVersion/startup1.png);
        background-size: 100% 100%;
                background-repeat: no-repeat;
                     overflow: hidden;
        background-position: center;
      }
      #curriculum_container{
        background-image: url(/media/src/images/MobileVersion/phase.jpg);
        background-size: 100% 100%;
                background-repeat: no-repeat;
                     overflow: hidden;
        background-position: center;
      }
      #open_ices{
        /* opacity: 0; */
      }
      .sign_img{
        width: 31.1%;
      
      }

      #map_signId_0{
        top: 27.1%;
    left: 35.5%;

      }
      #map_signId_1{
        top: 37.2%;
    left: 35.4%;  
      }
      #map_signId_2{
        top: 36.6%;
        left: 41.4%;
    
      }
      #map_signId_3{
        top: 36%;
    left: 51.7%;  
      }
      #map_signId_4{
        top: 7.6%;
        left: 50.6%;
      }
      #map_signId_5{
        top: 14.5%;
    left: 54.6%;
      }
      .Map_Dis_Block{
        width: 100%;
        height: 100%;
        
      }
      .sign_Container{
        position: absolute;
        /* transition: opacity 1s; */
        opacity: 0;
        transition: opacity 0.50s;
      }
      .Map_Discription_Context_Block{
        opacity: 1;
        /* transition: opacity 1s; */
        /* border: 1px solid blue; */
        /* overflow: hidden; */
        margin-top: 0%;
        WIDTH: 100%;
        height: 17%;
      }
      .Loc_img{
        width: 66%;
        margin-left: 17%;
        margin-top: 6%;
        HEIGHT: 88%;
      }
      #Loc_text_title{
        /* margin-top: 4%; */
        margin-top: 4.5%;
        /* margin-top: 4.8%; */
        font-family: 'montserrat_armstadart';
        font-size: 3.3vw;
        font-weight: bold;
      }
      #Loc_text_adress{
          font-family: 'montserrat_extralight';
          font-size: 2.5vw;
          margin-top: 0.8%;
      }
      .Img_Blocker{
        opacity: 0;
        transition: opacity 1s;
        width: 12%;
        height: 100%;
      }
      .Loaction_Text p{
        /* display: block; */
        /* margin-left:  */
        margin-left: -0.5%;
        display: block;
        /* margin-left: 1.5%; */
        color: white;
        font-size: 3.1vw;
        text-align: left;
      }
      .Loaction_Text{
        /* border: 1px solid red; */
        width: 86%;
    height: 100%;
      }
      .AnimOptimization{
        margin-top: 4.3%;
        position: relative;
        top: -9%;
    
      }
      .Map_Discription_Context_Block div{
        float: left;
      }
      .Map_Dis_Container{
        /* border: 1px solid red; */
        /* overflow: hidden; */
        height: 36.2%;
        width: 92%;
        left: 10.7%;
        position: absolute;
        top: 57.2%;
      }
      .Map_Container{
        height: 34%;
        width: 84%;
        position: absolute;
        top: 11%;
      }
      #home_1_3{
        top: 10.5%;
    font-family: 'montserrat_armblack';
    left: 9.4%;
    position: absolute;
      }
      .Conceptions_Container{
        position: absolute;
        height: 33%;
        width: 95.4%;
        /* background: yelsdasdsadsadasdasdlow; */
        /* border: 1px solid red; */
        top: 22%;
        left: 2%;
      }
      .Concept_Block_Block_Block div{
        float: left;
      }
      #top_block{

      }
      #bottom_block{
        padding-left: 15%;
      }
      .Texts_Container{
        position: absolute;
        left: 0.4%;
        top: 60.3%;
        width: 100%;
        height: 22%;
        /* border: 1px solid red; */
      }
      .Text_Block p{
        text-transform: uppercase;
        text-align: left;
        color: white;
        margin-top: -0.7%;
      }
      video{
        width: 100%;
        height: 100%;
      }
      .Text_Block{
        background-image: url(/media/src/images/MobileVersion/nshaner/12.png);
        background-repeat: no-repeat;
        font-size: 4.7vw;
        font-family: 'montserrat_extrabold';
        font-weight: bold;
        /* border: 1px solid violet; */
        background-size: 82% 96%;
        height: 21%;
        padding-top: 1%;
        padding-left: 5.7%;
        margin-left: 17.3%;
        /* position: relative; */
      
      
      }
      .Texts_Block{
        width: 100%;

        padding-top: 6%;
        height: 100%;
      }
      .Concept_Block_Block_Block{
        width: 100%;
        overflow: hidden;
        height: 54%;
        /* border: 1px solid greenyellow; */
      }
      #Concept_top_left{
        /* background-image: url('../images/MobileVersion/nshaner/11.png'); */
        background-repeat: no-repeat;
        background-size: 68.2% 77.7%;
        background-position: 110% 101%;
      }
      #Concept_bottom_left{
        /* background-image: url('../images/MobileVersion/nshaner/tt.png'); */
        background-repeat: no-repeat;
        background-size: 77.2% 74.7%;
        background-position: 90% 21%;
      }
      #Concept_bottom_right{
        /* background-image: url('../images/MobileVersion/nshaner/rr.png'); */
        background-repeat: no-repeat;
    background-size: 78.2% 74.7%;
    background-position: 32% 29%;
      }
      #Concept_top_right{
                /* background-image: url('../images/MobileVersion/nshaner/kk.png'); */
                background-repeat: no-repeat;
                background-size: 68.2% 75.7%;
                background-position: 2% 91%;
        /* background: red; */
      }
      #Concept_top_center{
        /* background-image: url('../images/MobileVersion/nshaner/hh.png'); */
        background-repeat: no-repeat;
        background-size: 68.2% 74.7%;
        width: 32.2%;
        background-position: 43% 88%;
      }
      .ConcetpContext_Container{
        width: 33%;
        height: 100%;
        border: 1px solid red;
      }
      .Conceptions_Container_Block{
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      .Center_Text_Container{
        position: absolute;
        height: 11%;
        width: 80%;
        color: #ffffff;
        /* font-family: 'montserrat_armstadart'; */
        text-transform: uppercase;
        /* border: 1px solid red; */
        left: 8.4%;
        top: 45%;
      }
      .Center_Text_Block h1{
        font-size: 7.9vw;
        text-align: center;
        margin-top: 12.5%;
    
      }
      .Center_Text_Block{
        width: 100%;
        height: 100%;
      }
      #Location_1_2_container{
        background-image: url("/media/src/images/MobileVersion/locations/Artboard 62 (2).png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      #curiculum_1_3{
        background-image: url("/media/src/images/MobileVersion/curiculum/Artboard 67s (2).png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      #curiculum_1_2{
        background-image: url(/media/src/images/MobileVersion/curiculum/fd.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      #cur_1_1_bigtext{
        left: 22.8%;
        top: 44.8%;
    /* font-size: 1vw !important; */
      }
      #cur_1_1_bigtext h1{
        font-size: 6vw;
        /* margin-left: 4%; */
        /* margin-top: 18.4%; */
      }
      #curiculum_1_1{
        background-image: url("/media/src/images/MobileVersion/curiculum/Artboard 651.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        /* background-position-x: -14.8vw; */
    
        /* background-size: cover;
        background-repeat: no-repeat;
        background-position-x: -17vw; */
      }
      .Otaparuk_text_container{
        height: 18%;
        width: 39%;
        /* border: 1px solid red; */
        position: absolute;
        left: 47%;
        top: 36.1%;
      }
      .Big_Text_Block{
        width: 100%;
        color: white;
        /* MARGIN-TOP: 28.8%; */
        position: relative;
        margin-left: 30.1%;
        font-size: 1.5vw;
        font-family: 'montserrat_armstadart';
        top: 25%;
    
        
      }
      .Big_Text_Block_2{
        width: 100%;
        color: white;
        /* MARGIN-TOP: 28.8%; */
        font-family: 'montserrat_extrabold' !important;
        /* margin-top: 22%; */
        position: absolute;
    top: 21.5%;
        left: 35.1%;
    
        font-size: 1.9vw;
        
      }
      .M_text_bottom{
        position: relative;
        top: 8%;
    
      }
      .M_text_bottom_2{
        position: relative;
        /* position: relative; */
        top: -26%;
        left: 3%;
    
    
      }
      .Mini_text_block{
        color: white;
        width: 100%;
        height: 18%;
        /* overflow: hidden; */
        font-family: 'montserrat_extralight';
        margin-left: 3.2%;
        /* background: red; */
        /* color: wheat; */
        font-size: 2.9vw;
        text-align: left;
        position: absolute;
        top: 74%;
      
      }
      #Otaparuk_2{
        left: 10.6%;
        top: 45.2%;
      }
      #Otaparuk_3{
        position: absolute;
    top: 74%;
    left: 0%;
      }
      .Big_Text_Block_3{
        position: absolute;
        top: 0%;
        left: 24%;
        color: white;
        font-family: 'montserrat_armstadart' !important;
        font-size: 1.9vw;
        font-weight: bold;
    
      }
      #Otaparuk_2 .Big_Text_Block{
        color: white;
        font-family: 'montserrat_extrabold' !important;
        /* margin-top: 22%; */
        position: relative;
        top: -7%;
        left: 6.1%;
        font-size: 1.9vw;
      }
      #dfr_2{
        color: transparent;
      }
      #Otaparuk_2 .Mini_text_block{
        /* background: red; */
        /* top: 85%; */
        top: 79%;
      }
      #Otaparuk_4{
        top: 62%;
        left: 63%;
      }
      #Otaparuk_4 .Mini_text_block{
           /* background: red; */
    top: 87%;
    left: -8%;
      }
      .text_trparent{
        color: transparent;
      }
      .Mini_text_block span{
        padding-left: 15%;
      }
      .Mini_text_Container{
        overflow: hidden;
        height: 60%;
        width: 100%;
        
        /* position: relative; */
        margin-top: 29%;
      }
      .Otaparuk_text_Block{
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      #Close_ices{
        position: absolute;
        
        opacity: 0;
        top: 0%;
        /* background: yellow; */
        background-image: url(/media/src/images/MobileVersion/robot/ices/close.png);
        background-repeat: no-repeat;
        background-size: 64% 13%;
        /* margin-left: 0; */
        background-position: 47.4%;
      }
      .Robot_ice_container{
        /* background: red; */
        height: 2.2%;
        width: 17%;
        position: absolute;
        top: 46.5%;
        left: 36.8%;
      }
      .Robot_Text_Container{
        /* background: wheat; */
        /* background: wheat; *5656565656*/
        color: white;
        position: absolute;
        transform: rotate(-6.5deg);
        top: 54.6%;
        left: 36%;
        height: 27%;
        width: 45.2%;
        text-align: left;
        font-size: 4vw; 
      }
      .robot_ices{
        /* opacity: 0; */
        transition: opacity 50ms;
        width: 100%;
        height: 100%;
        background-image: url(/media/src/images/MobileVersion/robot/ices/open.png);
        background-repeat: no-repeat;
        background-size: 70% 79.2%;
        /* margin-left: 0; */
        background-position: 47.2%;
        margin-left: 0;

    
      }
    
      .swiper-container-v {
        background: #eee;
        /* width: 100vw; */
        /* height: 100vh; */
      }
      .swiper-container {
        width: 100%;
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
      }

}


@media screen and (max-height:645px){
  .Map_Dis_Container{
    top: 56.8%;
  }
  #Loc_text_adress{
    margin-top: -0.6%;
  }
  .Center_Text_Block h1{
    margin-top: 8.5%;
  }
}

  