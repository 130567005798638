@media only screen and (min-width : 1224px) and (max-width: 1824px) {
    .s_idea h3{
        font-size: 38px;
    }
    .s_cop h3{
        font-size: 33px;
    }
    .slider_text_block{
        /* background: red; */
    }
    .slider_text_block h3{
        font-size: 23px;
        /* background-color: rebeccapurple; */
    }
}
@media only screen and (max-width: 1223px) {
    header{
        padding: 0 6px 0 6px;
        width: 100%;
    }
    #slider_text_block{
        font-size: 16px;
    }
    #its_arm {
        font-size: 25.1px;
        bottom: 16px;
        left: 7px;
    }
    #armer{
        font-size:16px
    }
    #haye {
        font-size: 16px;
    }
    #hjk{
        font-size: 16px;
        margin-top: 8vh;
    }
    #hjdk{
        font-size: 16px;
    }
    #hjdkd {
        font-size: 16px;
    }
    #sdasd {
        font-size: 24px;
    }
    .l_text{
        font-size: 27px;
    }
    .s_idea{
        background-position: -302px 0;
    }
    .s_dis_block::after{
        font-size: 190px;
    }
    .what_sec h2{
        font-size: 100px;
    }
    .slider_text_block{
        width: 100%;
    }
    .slider_text_block h3{
        margin-top: 2%;
        font-size: 19px;
        margin-right: 4.9vw;
        max-width: 53%;
    }
    .dubText {
        width: fit-content;
        position: relative;
        bottom: 28px;
        font-size: 38px;
        left: 15px;
    }
    .close{
        left: 89vw;
    }
    .bigMenuContainer{
        width: 100vw;
    }
    .menuUl li{
        margin-top: 50px;
    }
    .menuUl li a {
        /* margin: px; */
        font-size: 30px;
    }
    .menuUl li a{

    }
    .menuUl li a {
        font-size: 45px;
    }
    .h_context ul a{
        font-size: 18px;
    }
    .h_context ul li{
        margin-left: 15px;
    }
    .menuUl{
        margin-top: 92px;
    }
    .m_language{
        margin-left: 39px;
    }
    .s_logo{
        width: fit-content;
        height: 64px;
    }
    .h_context{
        justify-content:center;
        flex-wrap: nowrap;
    }
    .s_dis_block{
        margin-top: 41vh;
        margin-right: 17vw;
        /* margin-left: auto;    */
    }
    .s_sub_cont{
        margin-right: 30px;
    }
    .s_idea>h3{
        
        max-width: 54%;
        font-size: 28px;
        margin-top: 30vh;
        margin-right: 5vw;        
    }
    .s_cop h3{
        max-width: 45%;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 21px;
        margin-top: 19vh;
        margin-right: 5vw;    
    }
}
@media only screen and (max-width: 500px) {
    .what_sec h2{
        font-size: 70px;
    }
    #its_arm {
        bottom: 12px;
        left: 5px;
        font-size: 17.8px;
    }
    #arm_d{
        margin-right: 13%;
    }
    .m_language{
        margin-left: 18px;
    }
    .close{
        left: 89vw;
    }
    .school-header h1 {
        color: white;
        position: relative;
        z-index: 11;
        /* top: 40vh; */
        font-family: 'akrobatBlck';
        text-align: center;
        font-size: 75px;
    }
    .s_cop h3 {
        font-size: 17px;
        margin-top: 27vw;
        max-width: 79vw;
    }
    .menuUl li{
        margin-top: 50px;
    }
    .menuUl li a {
        /* margin: px; */
        font-size: 30px;
    }
    .sociales a {
        height: 10vw;
        width: 11vw;
    }
    .socialnav {
        left: 89vw;
    }
    .bigMenuContainer{
        width: 100vw;
    }
    .bgr4 h3{
        font-size: 17px;
        margin-top: 9vw;
        max-width: 46vw;
      }
    body{
        overflow:revert;
    }
    .s_idea>h3 {
        max-width: 75vw;
        margin-top: 36vw;
        font-size: 21px;    
    }
    .slider_text_block h3{
        max-width: 52%;
        font-size: 14px;
    }
    .slider_text_block{
        width: 100%;
    }
    .slider_text_block h3:nth-child(1) {

    }
    .s_dis_block{
        margin-top: 46vh;
        margin-right: 22vw;
    }    
    #sss_to_1 h3{
        margin-top: 77vw;
    }
    .dubText{
        width: fit-content;
        position: relative;
        bottom: 15px;
        font-size: 27px;
        left: 13px;
    }
    .s_logo img{
        display: block;
    }
    .s_logo{
        height: 57px;
    }
    .s_dis_block::after{
        top: 5px;
        font-size: 140px;
    }
    .bgr5, .s_idea{
        background-position: -309px;
    }
    #armer{
        font-size:15px
    }
    #haye {
        font-size: 15px;
    }
    #hjk{
        font-size: 15px;
        margin-top: 8vh;
    }
    #hjdk{
        font-size: 15px;
    }
    #hjdkd {
        font-size: 12px;
    }
    .l_text{
        font-size: 24px;
    }
    #sdasd {
        font-size: 20px;
    }
    #bdx{
        font-family: 'montserrat_armblack';
        font-size: 50px;
    }
    #slider_text_block h3 {
        max-width: 62%;
        font-size: 13px;
        
    }
}
