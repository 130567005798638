


@media only screen and (max-width: 7680px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 13.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 13.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 13.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 21.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 15.7262vw !important;
    }
}
@media only screen and (max-width: 6000px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 18.7262vw !important;
    }
}

@media only screen and (max-width: 5900px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 18.7262vw !important;
    }
}
@media only screen and (max-width: 5700px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 18.7262vw !important;
    }
}
@media only screen and (max-width: 5600px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 18.7262vw !important;
    }
}
@media only screen and (max-width: 5400px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 18.7262vw !important;
    }
}

@media only screen and (max-width: 5300px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 17.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 18.7262vw !important;
    }
}
@media only screen and (max-width: 5200px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 21.7262vw !important;
    }
}
@media only screen and (max-width: 5100px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 21.7262vw !important;
    }
}
@media only screen and (max-width: 5000px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 21.7262vw !important;
    }
}
@media only screen and (max-width: 4900px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 21.7262vw !important;
    }
}
@media only screen and (max-width: 4800px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
}

@media only screen and (max-width: 4700px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
}
@media only screen and (max-width: 4600px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
}

@media only screen and (max-width: 4500px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
}


@media only screen and (max-width: 4400px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
}

@media only screen and (max-width: 4300px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 34.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 34.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
}

@media only screen and (max-width: 4200px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 34.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 34.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
}
@media only screen and (max-width: 4100px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 24.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 34.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 34.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
}

@media only screen and (max-width: 4000px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 25.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 25.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 25.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 39.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 39.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
}

@media only screen and (max-width: 3900px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
}

@media only screen and (max-width: 3800px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
}
@media only screen and (max-width: 3700px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
}
@media only screen and (max-width: 3600px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
}
@media only screen and (max-width: 3500px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
}

@media only screen and (max-width: 3400px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 42.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
}
@media only screen and (max-width: 3300px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 29.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 44.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 44.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 34.7262vw !important;
    }
}
@media only screen and (max-width: 3200px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 47.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 47.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 37.7262vw !important;
    }
}
@media only screen and (max-width: 3100px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 40.7262vw !important;
    }
}
@media only screen and (max-width: 3000px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 40.7262vw !important;
    }
}
@media only screen and (max-width: 2900px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 40.7262vw !important;
    }
}

@media only screen and (max-width: 2800px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 40.7262vw !important;
    }
}

@media only screen and (max-width: 2700px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 35.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 50.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 55.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 40.7262vw !important;
    }
}

@media only screen and (max-width: 2652px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
}

@media only screen and (max-width: 2600px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
}

@media only screen and (max-width: 2500px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 30.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
}

@media only screen and (max-width: 2400px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 30.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
}

@media only screen and (max-width: 2300px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 20.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
}
@media only screen and (max-width: 2200px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 23.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 23.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 22.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 32.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 33.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 26.7262vw !important;
    }
}
@media only screen and (max-width: 2000px) {
  /* //35 */
  #text1 .bigtext>div>span{
      transform-origin: center 25.7262vw !important;
      /* background: red; */
    }
    #text2 .bigtext>div>span{
      transform-origin: center 23.7262vw !important;
    } 
    #text6 .bigtext>div>span{
      transform-origin: center 25.7262vw !important;
    }
    /*  */
    #text3 .bigtext>div>span{
      transform-origin: center 36.7262vw !important;
    }
    #text4 .bigtext>div>span{
      transform-origin: center 40.7262vw !important;
    }
    #text5 .bigtext>div>span{
      transform-origin: center 27.7262vw !important;
    }
}
/* //////////////////////// */
@media only screen and (max-width: 1920px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 25.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 25.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 25.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 38.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 43.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 32.7262vw !important;
      }
  }

  @media only screen and (max-width: 1600px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 34.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 34.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 34.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 46.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 47.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 36.7262vw !important;
      }
  }

  @media only screen and (max-width: 1500px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 36.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 36.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 36.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 48.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 49.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 38.7262vw !important;
      }
  }
  @media only screen and (max-width: 1400px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 38.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 38.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 38.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 55.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 55.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 40.7262vw !important;
      }
  }

  @media only screen and (max-width: 1300px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 40.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 40.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 40.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 52.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 52.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 42.7262vw !important;
      }
  }

  @media only screen and (max-width: 1200px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 45.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 45.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 45.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 57.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 57.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 47.7262vw !important;
      }
  }

  @media only screen and (max-width: 1100px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 50.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 50.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 50.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 62.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 62.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 52.7262vw !important;
      }
  }

  @media only screen and (max-width: 1000px) {
    /* //35 */
    #text1 .bigtext>div>span{
        transform-origin: center 55.7262vw !important;
        /* background: red; */
      }
      #text2 .bigtext>div>span{
        transform-origin: center 55.7262vw !important;
      } 
      #text6 .bigtext>div>span{
        transform-origin: center 55.7262vw !important;
      }
      /*  */
      #text3 .bigtext>div>span{
        transform-origin: center 72.7262vw !important;
      }
      #text4 .bigtext>div>span{
        transform-origin: center 72.7262vw !important;
      }
      #text5 .bigtext>div>span{
        transform-origin: center 62.7262vw !important;
      }
  }